import { Menu, Transition } from "@headlessui/react";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import DeleteParticipant from "../../../../components/Modals/DeleteParticipant";
import EditParticipantDetails from "../../../../components/Modals/EditParticipantDetails";
import {
  toogleDeleteParticipantmodal,
  toogleEditParticipantModal,
} from "../../../../store/modal/modalSlice";
import { 
  useGetAllParticipantsQuery,
  useLazyGetAllParticipantsQuery,
} from "../../../../utils/api/event/eventApi";
import RoleComponent from "../../../../components/RoleComponent";
import { useAuth } from "../../../../hooks/useAuth"; 
 
const EventParticipants = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const user: UserType = useAuth();
  const [eventParams, setEventParams] = useState({
    slug: params.slug,
    page: 1,
  });
  const [participantsSheetData, setParticipantsSheetData] = useState<any>([]);
  // requests
  const {
    data: participantsData,
    isLoading: participantsIsLoading,
    isFetching: participantsIsFetching,
    isError: participantsIsError,
  } = useGetAllParticipantsQuery({
    slug: params.slug || "",
    search: location.search,
  });

  const [selectedParticipant, setSelectedParticipant] = useState({
    id: 0,
    first_name: "",
    email: "",
    uid: "",
  });
  const deleteParticipantHandler = (participant: ParticipantType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleDeleteParticipantmodal(true));
  };

  // const viewPosterClickHandler = (uuid: string) => () => {
  //   navigate(
  //     `/dashboard/document?event=${eventParams.slug}&type=tag&uid=${uuid}`
  //   );
  // };
  const editProfileClickHandler = (participant: ParticipantType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleEditParticipantModal(true));
  };

  const [getAllParticipants, { data, isLoading, isError }] =
    useLazyGetAllParticipantsQuery({});

  const generateSheet = async () => {
    console.log("generate sheet");
    const getAllParticipantsResponse = await getAllParticipants({
      slug: params.slug || "",
      search: '?all=true',
    }).unwrap();

    setParticipantsSheetData(getAllParticipantsResponse.participants); // update state
  };

  const nextPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage + 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const previousPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage - 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <DeleteParticipant
        eventSlug={params.slug || ""}
        {...{ selectedParticipant }}
      />
      <EditParticipantDetails selectedParticipant={selectedParticipant} />
      <div className="relative">
        <header className="bg-white shadow-sm relative  ">
          <div className="max-w-7xl mx-auto py-4 px-6 sm:px-8 xl:px-0">
            <h1 className="text-lg leading-6 font-semibold text-gray-900">
              Participants
            </h1>
          </div>
        </header>

        <main className="relative   bg-gray-100">
          <div className="max-w-7xl mx-auto py-6 px-6 sm:px-8 xl:px-0 relative  ">
            <RoleComponent
              role={user.role?.role || "user"}
              allowedRoles={["manager", "admin"]}
            >
              <div className="flex w-full space-x-1 justify-end">
                <button
                  type="button"
                  onClick={() => generateSheet()}
                  className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400"
                >
                  <CSVLink data={participantsSheetData}
                    asyncOnClick={true}
                    onClick={() => generateSheet()}
                    >
                    {isLoading ? "Loading..." : "Get All Participants"}
                  </CSVLink>
                  {/* <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div> */}
            
                </button>
              </div>
            </RoleComponent>
            {/* Main 3 column grid */}
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Designation
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Actions
                            </th>
                            {/* <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                           
                        </th> */}
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 bg-white">
                          {/* loading */}
                          {participantsIsLoading && (
                            <tr>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-200 animate-pulse "></div>
                                  <div className="ml-4 w-full">
                                    <div className="bg-gray-200 animate-pulse w-full h-6 my-1"></div>
                                    <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                              </td>

                              <td className="flex items-center h-24 w-full space-x-2 px-4">
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                              </td>
                            </tr>
                          )}
                          {/* error */}
                          {participantsIsError && (
                            <tr>
                              <td className=" py-4 pl-4 pr-3 text-sm  ">
                                An error occured, try refreshing...
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}

                          {participantsData && (
                            <>
                              {participantsData.participants.map(
                                (participant: ParticipantType) => (
                                  <tr key={participant.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                      <div className="flex items-center">
                                        {participantsIsFetching ? (
                                          <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 animate-pulse "></div>
                                        ) : (
                                          <div className="h-10 w-10 flex-shrink-0">
                                            <img
                                              className="h-10 w-10 rounded-full"
                                              src={
                                                participant.details?.image
                                                  ? participant.details?.image.includes(
                                                      "https"
                                                    )
                                                    ? participant.details?.image
                                                    : `${process.env.REACT_APP_IMAGE_URL}images/${participant.details?.image}`
                                                  : "/images/placeholder.jpg"
                                              }
                                              alt=""
                                            />
                                          </div>
                                        )}

                                        <div className="ml-4 w-full">
                                          {participantsIsFetching ? (
                                            <>
                                              <div className="bg-gray-300 animate-pulse w-full h-6 my-1"></div>
                                              <div className="bg-gray-300 animate-pulse w-full h-6"></div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="font-medium text-gray-900">
                                                {participant.title}{" "}
                                                {participant.first_name}{" "}
                                                {participant.last_name}
                                              </div>
                                              <div className="text-gray-500">
                                                {participant.email}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {participant.designation_name}
                                    </td>

                                    <td className="flex items-center justify-end space-x-3 lg:space-x-6   py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                                      {/* <button
                                      className="bg-green-900 text-white p-1 rounded-md h-full  w-6"
                                      onClick={viewPosterClickHandler(
                                        participant.uid
                                      )}
                                    >
                                      <EyeIcon />
                                    </button> */}
                                      <Menu as="div" className="ml-3 relative">
                                        <Menu.Button>
                                          <div className="bg-green-900 text-white p-1 rounded-md h-full  w-6">
                                            <EyeIcon />
                                          </div>{" "}
                                        </Menu.Button>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0   mt-2 w-24 rounded-md shadow-lg py-1 z-50 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">
                                            <Menu.Item>
                                              <Link
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full cursor-pointer text-left"
                                                to={`/dashboard/document?event=${eventParams.slug}&type=poster&uid=${participant.uid}&designation=${participant.designation_name}`}
                                              >
                                                Poster
                                              </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                              <Link
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full cursor-pointer text-left"
                                                to={`/dashboard/document?event=${eventParams.slug}&type=tag&uid=${participant.uid}`}
                                              >
                                                Tag
                                              </Link>
                                            </Menu.Item>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>

                                      <button
                                        className="bg-blue-900 text-white p-1 rounded-md h-full  w-6"
                                        onClick={editProfileClickHandler(
                                          participant
                                        )}
                                      >
                                        <PencilIcon />
                                      </button>
                                      <button
                                        onClick={deleteParticipantHandler(
                                          participant
                                        )}
                                        className="bg-red-900 text-white p-1 rounded-md h-full w-6"
                                      >
                                        <TrashIcon />
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav
              className="bg-white lg:mx-8  px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  <span className="font-medium">
                    {participantsData &&
                      `Showing ${participantsData?.current_page} `}
                  </span>

                  <span className="font-medium">
                    {participantsData && `to  ${participantsData?.last_page} `}
                  </span>
                  <span className="font-medium">
                    {participantsData &&
                      `of ${participantsData?.total} results`}
                  </span>
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <button
                  disabled={(participantsData?.current_page || 1) <= 1}
                  onClick={() => previousPageHandler()}
                  className="relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md text-white bg-blue-700  hover:bg-blue-500 disabled:bg-gray-400"
                >
                  Previous
                </button>
                <button
                  disabled={
                    (participantsData?.current_page || 1) >=
                    (participantsData?.last_page || 1)
                  }
                  onClick={() => nextPageHandler()}
                  className="ml-3 relative inline-flex items-center px-4 py-2   text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-500 disabled:bg-gray-400"
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </main>
      </div>
    </>
  );
};

export default EventParticipants;
