import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { getMainNavigation } from "../../store/navigation/navigationSlice";

const Navigation = () => {
  const navigation = useSelector(getMainNavigation);

  return (
    <motion.div
    initial={{y: -50}}
    animate={{y:0}}
    >
      <Disclosure as="nav" className="bg-alternate-dark dark:bg-alternate-darker dark:text-gray-100 sticky top-0 z-10">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-6 sm:px-8 xl:px-0">
              <div className="flex justify-between h-16">
                <div className="flex md:justify-between md:w-full">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-shrink-0 flex items-center">
                 <Link to={"/"}>
                   <img
                      className="block h-7 lg:h-8 w-auto"
                      src="/images/lawrify_full_white.svg"
                      alt="lawrify"
                    />
                 </Link>
                   
                  </div>
                  <div className="hidden md:mr-8 md:flex md:items-center md:space-x-4  ">
                    {navigation.map((item) => (
                      <NavLink
                        end
                        key={item.name}
                        to={item.path}
                        className={({ isActive }) =>
                          +isActive
                            ? "bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        }
                        // aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="auth">
                      <div className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500">
                        <span>Login / Register</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    className={"block  w-full text-left"}
                    // aria-current={item.current ? 'page' : undefined}
                  >
                    <NavLink
                      key={item.name}
                      to={item.path}
                      className={({ isActive }) =>
                        +isActive
                          ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                      }
                      // aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name} 
                    </NavLink>
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </motion.div>
  );
};

export default Navigation;
