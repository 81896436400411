import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  chapter: "creative",
  mainNavigation: [
    { name: "Home", path: "/" },
    { name: "Events", path: "/events" },
    { name: "Branches", path: "/branches" },
  ],
  dashboardNavigation: [
    { name: "Dashboard", path: "/dashboard", role: ["user", "manager", "admin"],},
    {
      name: "Profile",
      path: "/dashboard/profile",
      role: ["user", "manager", "admin"],
    },
    { name: "Branch", path: "/dashboard/branch", role: ["manager", "admin"], },
  ],
  userNavigation: [{ name: "Profile", path: "/dashboard/profile" }],
  redirectUrl: `/`,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
  },
});

export const { setRedirectUrl } = navigationSlice.actions;
export default navigationSlice.reducer;

// selectors
export const selectRedirectUrl = (state: RootState) =>
  state.navigation.redirectUrl;
export const selectChapter = (state: RootState) => state.navigation.chapter;
export const getMainNavigation = (state: RootState) =>
  state.navigation.mainNavigation;
export const getDashboardNavigation = (state: RootState) =>
  state.navigation.dashboardNavigation;
export const getUserNavigation = (state: RootState) =>
  state.navigation.userNavigation;
