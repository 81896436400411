import { FC, MouseEventHandler, ReactNode } from "react";
import { BackgroundColor, ButtonSize, Rounded, TextColor, Width } from "./ButtonTheme";
 
export interface ButtonType {
  children?: ReactNode;
  as?:"button"|"div";
  color: "primary" | "secondary" | "white" | "dark" | "tertiary";
  size?: "xs" | "sm" | "md" | "base" | "lg";
  className?:string;
  circular?: true|false;
  block?:true|false;
  background?: true|false;
  disabled?: true|false;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClick?:MouseEventHandler<HTMLButtonElement>
}
const Button: FC<ButtonType> = ({
  children,
  color = "primary",
  size = "base",
  className,
  background = true,
  circular=false,
  block=false,
  onClick,
  as="button",
  iconLeft=<></>,
  iconRight=<></>,
  ...props
}) => {
  const classNames = `
  ${className}
${ButtonSize[size as keyof typeof ButtonSize]}

${
  background
    ? BackgroundColor[color as keyof typeof BackgroundColor]
    : TextColor[color as keyof typeof TextColor]
}

${
  block
    ? Width["full"]
    : Width["auto"]
}

${circular?
  Rounded["full"]:
  Rounded["md"]
}
`;
  return (
   <>
    {as==="button"?(<button {...props} 
    onClick={onClick}
    className={classNames}>
      {iconLeft}
      {children}
      {iconRight}
    </button>)
    :
    (<span {...props} 
      onClick={onClick}
      className={classNames}>
        {iconLeft}
        {children}
        {iconRight}
      </span>)
      }
   </>
  );
};
 export default Button