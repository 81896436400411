import React, { useCallback, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import PreviewCard from "../../../components/PreviewCard/PreviewCard";
import { useGetUserWithCodeQuery } from "../../../utils/api/user/userApi";
import { toPng } from "html-to-image";
import Loading from "../../../components/Loading/Loading";
import RoleComponent from "../../../components/RoleComponent";
import { useAuth } from "../../../hooks/useAuth";

const Profile = () => {
  const user = useAuth();
  let [searchParams] = useSearchParams();
  let location = useLocation();
  const [isAdmin, setIsAdmin] = useState(
    location.pathname === "/dashboard/admin/user/new"||location.pathname === "/dashboard/admin/user/view"
  );
 const[imageName, setImageName] = useState("image")
 const setImageNameFromPreview = (name:string)=>{
  setImageName(name)
 }
  const qrCodeContainer = useRef<HTMLDivElement>(null);
  const userFullName = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  // const handleQRCodeDownload = ()=>  console.log('clicked',qrCodeContainer.current)
  const handleQRCodeDownload = () => {
    console.log("clicked", userFullName.current?.innerText);
    setIsDownloading(true);

    setTimeout(() => {
      // Add a 2.5-second timeout

      if (qrCodeContainer.current === null) return;
      toPng(qrCodeContainer.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${userFullName.current?.innerText.trim()||"image"}_qrcode.png`;
          link.href = dataUrl;
          link.click();
          setIsDownloading(false);
        })
        .catch((error) => {
          console.error("Oops, something went wrong!", error);
        });
    }, 2500); // 2.5 seconds in milliseconds
  };
  return (
    <>
      <Loading show={isDownloading} />
      <RoleComponent
        role={user.role?.role || "user"}
        allowedRoles={["admin", "agent"]}
      >
        <div className="white shadow-xl px-3 py-6 bg-white">
          Admin Controls
          <div className="flex space-x-2 mt-2">
            <button
              type="button"
              className="  inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                  disabled:bg-green-400
                  "
              onClick={handleQRCodeDownload}
            >
              Download QR Code
            </button>{" "}
            <Link
              to={"/dashboard/admin/users"}
              type="button"
              className="  inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
                  disabled:bg-yellow-400
                  "
            >
              View Users
            </Link>
            <Link
              to={"/dashboard/admin/user/new"}
              className="  inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  disabled:bg-blue-400
                  "
            >
              Create new User
            </Link>
          </div>
        </div>
      </RoleComponent>

      <section className="relative max-w-7xl mx-auto   py-8 px-6 sm:px-8 xl:px-0 ">
        <div className="flex justify-center  ">
          <div className=" ">
            <PreviewCard
                isAdmin={isAdmin}
              uid={searchParams.get("code")}
              fromLink={true}
              qrCodeRef={qrCodeContainer}
              userFullName={userFullName}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
