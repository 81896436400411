import DashboardNavigation from "../../../components/Navigation/DashboardNavigation";

import { Outlet } from "react-router-dom";
import Notification from "../../../components/Notification/Notification";
 
import { useSelector } from "react-redux";
import { showEventRegistrationSteps } from "../../../store/user/userSlice";
import Steps from "../../../components/Steps/Steps";

export default function Home() {
  const showSteps = useSelector(showEventRegistrationSteps)
 
  return (
    <>
      <Notification type={"normal"} title={""} />
     {showSteps && <Steps></Steps>}
      <div className="min-h-full bg-gray-100">
        <DashboardNavigation />
        <Outlet />
      </div>
    </>
  );
}
