import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {toogleRegistrationSteps } from "../../store/user/userSlice";
const stepDetails = [
  {
    id: 1,
    title: "Title one",
    description: "Description One",
    image: "https://i.ibb.co/pyFrKM1/step1-min-1.jpg",
  },
  {
    id: 2,
    title: "Title Two",
    description: "Description Two",
    image: "https://i.ibb.co/JCGTGSN/step2.jpg",
  },
  {
    id: 3,
    title: "Title one",
    description: "Description One",
    image: "https://i.ibb.co/3N7X486/step3.jpg",
  },
  {
    id: 4,
    title: "Title Two",
    description: "Description Two",
    image: "https://i.ibb.co/Wz6974h/step4.jpg",
  },
  {
    id: 5,
    title: "Title Two",
    description: "Description Two",
    image: "https://i.ibb.co/ZSKB4kN/step5.jpg",
  },
];
const Steps = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  return (
    <div
      className="h-screen w-full fixed top-0 z-[999990]
     bg-black bg-opacity-80"
    >
      <div className="flex  p-8  w-full h-full items-center justify-center">
        {stepDetails.map((step, idx) => (
          <div
            key={idx}
            className={
              currentStep === idx + 1
                ? "opacity-100 absolute px-8"
                : "opacity-0 absolute px-8"
            }
          >
            <img src={step.image} alt="" />;
          </div>
        ))}
        <div className="w-full lg:w-64 flex justify-between absolute bottom-0 pb-4 px-16 lg:px-0">
          <button
            disabled={currentStep <= 1}
            className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500disabled:bg-blue-400 disabled:bg-gray-200"
            onClick={() => {
              currentStep > 1 && setCurrentStep(currentStep - 1);
            }}
          >
            Previous
          </button>
          {currentStep !== stepDetails.length ? (
            <button
              disabled={currentStep >= stepDetails.length}
              className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500disabled:bg-green-400 disabled:bg-gray-300"
              onClick={() => {
                currentStep < stepDetails.length &&
                  setCurrentStep(currentStep + 1);
              }}
            >
              Next
            </button>
          ) : (
            <button
             
              className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500disabled:bg-green-400 disabled:bg-gray-300"
              onClick={() => {
                dispatch(toogleRegistrationSteps(false))
              }}
            >
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Steps;
