import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const branchSlice = createSlice({
  name: "branch",
  initialState: {
    selectedBranch: {} as BranchType,
    branches: [] as BranchType[],
  },
  reducers: {
    setBranch: (state, action) => {state.selectedBranch = action.payload},
    setAllBranches: (state, action) => {state.branches = action.payload},
  },
});

export const { setBranch } = branchSlice.actions;
export default branchSlice.reducer;

export const selectCurrentBranch = (state: RootState) => state.branch;
