import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE, } from "redux-persist";
import { RootState } from "../store";
import storage from "redux-persist/lib/storage";
interface UserInitialState{
  user: UserType,
  token:string,
  showEventRegistrationSteps?:boolean
}
const initialState :UserInitialState= {
  user: {id:0,first_name:"", email:"", uid:"", details:{}},
  token: "",
  showEventRegistrationSteps:false,
}


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserFullname:(state, action)=>{
      state.user.title=action.payload.title;
      state.user.first_name=action.payload.first_name;
      state.user.last_name=action.payload.last_name;
    },
    setUserPhone:(state, action)=>{
      state.user.details.phone = action.payload
    },
    setUserImage: (state, action) => {
      state.user.details.image = action.payload;
    },
    setVerificationStatus:(state, action)=>{
      state.user.verified=action.payload
    },
    toogleRegistrationSteps:(state,action)=>{
      state.showEventRegistrationSteps=action.payload
    }
  },
  extraReducers:(builder)=>{
    builder.addCase(PURGE, ()=>{
      storage.removeItem("root")
    })
  }
});
export const { setCredentials, setUser,setUserImage, setVerificationStatus,setUserFullname, setUserPhone, toogleRegistrationSteps} = userSlice.actions;
export default userSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.user.user;
export const selectUserEvents = (state: RootState) => state.user.user.events;
export const getUserToken = (state: RootState) => state.user.token;
export const getUserImage = (state: RootState)=>state.user.user.details.image || ''
export const showEventRegistrationSteps=(state: RootState)=>state.user.showEventRegistrationSteps
