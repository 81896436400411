/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { useAuth, useImage } from "../../hooks/useAuth";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  getDashboardNavigation,
  getUserNavigation,
} from "../../store/navigation/navigationSlice";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../store/store";
import RoleComponent from "../RoleComponent";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const DashboardNavigation = () => {
  const userImage = useImage();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigation = useSelector(getDashboardNavigation);
  const userNavigation = useSelector(getUserNavigation);
  const user = useAuth();

  const logOutUserHandler = () => {
    persistor.purge();
    navigate("/");
    console.log("log out user");
  };
  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 relative z-50">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-6 sm:px-8 xl:px-0">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src="https://i.ibb.co/x7kRJTZ/lawrify.png"
                      alt="lawrify"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <RoleComponent
                          key={item.name}
                          role={user?.role?.role || "user"}
                          allowedRoles={["admin", "agent"]}
                        >
                          <NavLink
                            end
                            // key={item.name}
                            to={item.path}
                            className={({ isActive }) =>
                              +isActive
                                ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            }
                            // aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </NavLink>
                        </RoleComponent>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={
                              userImage
                                ? userImage.includes("https")
                                  ? userImage
                                  : `${process.env.REACT_APP_IMAGE_URL}images/${userImage}`
                                : "/images/placeholder.jpg"
                            }
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.path}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full cursor-pointer text-left"
                              onClick={logOutUserHandler}
                            >
                              Logout
                            </button>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            {/* mobile menu */}
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    className={"block  w-full text-left"}
                    // aria-current={item.current ? 'page' : undefined}
                  >
                    <NavLink
                      key={item.name}
                      to={item.path}
                      className={({ isActive }) =>
                        +isActive
                          ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                      }
                      // aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </NavLink>
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={
                        userImage
                          ? userImage.includes("https")
                            ? userImage
                            : `${process.env.REACT_IMAGE_URL}images/${userImage}`
                          : "/images/placeholder.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      className="w-full block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      <NavLink
                        to={item.path}
                        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-left"
                      >
                        {item.name}
                      </NavLink>
                    </Disclosure.Button>
                  ))}
                  <Disclosure.Button className="w-full block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
                    <div
                      className="cursor-pointer text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium  "
                      onClick={logOutUserHandler}
                    >
                      Logout
                    </div>
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default DashboardNavigation;
