
import { usePaystackPayment } from 'react-paystack';

const PaymentWithPaystack = ({ amount = 0, paymentResponse, email="user@email.com"}) => {
  const config = {
    reference: (new Date()).getTime().toString(),
    email,
    amount,
    publicKey: 'pk_test_8419347ca7c1fb9f3a6d8c27607a10baf5c7040e',
  };

  // you can call this function anything
  const handleSuccess = (ref) => {
    // Implementation for whatever you want to do with reference and after success call.
    //  console.log(ref);
    paymentResponse(ref) //this would make the ref accessible in the parent component if you need it there

  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }
 
  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div>

        <button className="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                  disabled:bg-green-400
                  " onClick={() => {
            initializePayment(handleSuccess, onClose)
          }}>Proceed to pay</button>
      </div>
    );
  };
  return (
    <div>

      <PaystackHookExample />
    </div>

  )
}

export default PaymentWithPaystack