/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleIcon,
  ExclamationIcon,
  SpeakerphoneIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import {
  notification,
  NotificationState,
  toogleNotification,
} from "../../store/modal/notificationSlice";

const Notification: FC<NotificationType> = ({ type = "normal" }) => {
  // const [show, setShow] = useState(true);
  const show = useSelector(NotificationState);
  const dispatch = useDispatch();
  const message = useSelector(notification);
  let bgColor;
  let borderColor;
  let textColor: string;
  let icon;
  let notificationType:NotificationType['type']= message.type||type;
  switch (notificationType) {
    case "error":
      // bgColor = "bg-[#ffdcdc]";
      // borderColor = "border-[#d90000]";
      bgColor = "bg-[#ffdcdc]";
      borderColor = "border-[#d90000]";
      textColor = "text-color-400";
      icon = (
        <XCircleIcon className={`h-6 w-6 ${textColor}`} aria-hidden="true" />
      );
      break;
    case "normal":
      bgColor = "bg-white";
      borderColor = "border-gray-800";
      textColor = "text-gray-400";
      icon = (
        <SpeakerphoneIcon
          className={`h-6 w-6 ${textColor}`}
          aria-hidden="true"
        />
      );
      break;
    case "success":
      bgColor = "bg-[#cbffce]";
      borderColor = "border-[#008c07]";
      textColor = "text-green-400";
      icon = (
        <CheckCircleIcon
          className={`h-6 w-6 ${textColor}`}
          aria-hidden="true"
        />
      );
      break;
    case "warning":
      bgColor = "bg-[#ffedc3]";
      borderColor = "border-[#c08600]";
      textColor = "text-amber-400";
      icon = (
        <ExclamationIcon
          className={`h-6 w-6 ${textColor}`}
          aria-hidden="true"
        />
      );
      break;
    default:
      bgColor = "bg-white";
      borderColor = "border-gray-800";
      textColor = "text-gray-400";
      icon = (
        <SpeakerphoneIcon
          className={`h-6 w-6 ${textColor}`}
          aria-hidden="true"
        />
      );
      break;
  }
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`max-w-sm w-full ${bgColor} ${borderColor}   border shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-bold text-gray-900">
                    {message.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-700">
                      {message.description!}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        dispatch(toogleNotification(false));
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notification;
