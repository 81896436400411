import React from "react";
import { useEventListQuery } from "../../utils/api/event/eventApi";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Search from "../Search/Search";
import ListPagination from "../Navigation/ListPagination";

const EventTable = () => {
  const {
    data: eventListData,
    refetch: refetchEventListData,
    isLoading: eventListIsLoading,
    isFetching: eventListIsFetching,
    isError: eventListIsError,
  } = useEventListQuery("");

  return (
    <div>
      <div className="relative">
        <header className="bg-white shadow-sm relative  ">
          <div className="max-w-7xl mx-auto py-4 px-6 sm:px-8 xl:px-0">
            <h1 className="text-lg leading-6 font-semibold text-gray-900">
              Events
            </h1>
          </div>
        </header>

        <main className="relative   bg-gray-100 p-4">
          <div className="max-w-7xl mx-auto  relative  ">
            {/* Main 3 column grid */}
            <div className=" ">
              <div className=" flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <Search title={"Events"} />
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Actions
                            </th>
                            {/* <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                             
                          </th> */}
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 bg-white">
                          {/* loading */}
                          {eventListIsLoading || eventListIsFetching ? (
                            <tr>
                              <td className=" py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-200 animate-pulse "></div>
                                  <div className="ml-4 w-full">
                                    <div className="bg-gray-200 animate-pulse w-full h-6 my-1"></div>
                                    <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                                  </div>
                                </div>
                              </td>
                              <td className=" px-3 py-4 text-sm text-gray-500">
                                <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                              </td>

                              <td className="flex items-center h-24 w-full space-x-2 px-4">
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {eventListData?.data.map((event: EventType) => (
                                <tr key={event.id}>
                                  <td className="  py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                      <div className="h-10 w-10 flex-shrink-0">
                                        <img
                                          className="h-10 w-10 rounded-full"
                                          src={event.image}
                                          alt={event.name}
                                        />
                                      </div>

                                      <div className="ml-4 w-full">
                                        <>
                                          <div className="font-medium text-gray-900">
                                            {event.name}
                                          </div>
                                          <div className="text-gray-500">
                                            {event.branch?.name}
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-3 py-4 text-sm text-gray-500">
                                    <div className="text-gray-900">
                                      {dayjs(event.start_date).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </div>
                                  </td>

                                  <td className="flex items-center justify-end space-x-3 lg:space-x-6   py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                                    {/* <button
                                      className="bg-green-900 text-white p-1 rounded-md h-full  w-6"
                                      onClick={viewPosterClickHandler(
                                        participant.uid
                                      )}
                                    >
                                      <EyeIcon />
                                    </button> */}
                                    <div className="flex space-x-2">
                                      <Link
                                        className="bg-blue-900 text-white p-1 rounded-md h-full  "
                                        to={`/dashboard/event/participants/${event.slug}`}
                                      >
                                        Participants
                                      </Link>
                                      <Link
                                        className="bg-green-900 text-white p-1 rounded-md h-full  "
                                        to={`/dashboard/event/${event.slug}`}
                                      >
                                        View
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          {/* error */}
                          {eventListIsError && (
                            <tr>
                              <td className=" py-4 pl-4 pr-3 text-sm  ">
                                An error occured, try refreshing...
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div>
                        <ListPagination {...eventListData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EventTable;
