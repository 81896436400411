import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
const initialState={ 
   selectedEvent:{} as EventType,
   events:[] as EventType[]
}
export const eventSlice = createSlice({
    name:'event',
    initialState,
    reducers:{
        setEvent:(state,action)=>{state.selectedEvent=action.payload}
    }
})

export const {setEvent}= eventSlice.actions
export default eventSlice.reducer

// selectors
export const selectCurrentEvent = (state:RootState)=>state.event.selectedEvent