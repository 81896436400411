import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  editFullnameModal,
  toogleEditFullnameModal,
} from "../../store/modal/modalSlice";
import { useEditFullnameMutation } from "../../utils/api/user/userApi";
import { setUserFullname } from "../../store/user/userSlice";
import Alert from "../Alert/Alert";

const EditFullName: FC<{ user?: UserType }> = ({
  user = { title: "", first_name: "", last_name: "" },
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  const open = useSelector(editFullnameModal);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  // validation schema
  const schema = yup
    .object({
      title: yup.string().required("Please select a title"),
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("title", user.title);
    setValue("firstName", user.first_name);
    setValue("lastName", user.last_name);
  }, [setValue, user]);

  const [
    editUserFullname,
    { isLoading: editUserFullnameIsLoading, isError: editUserFullnameIsError },
  ] = useEditFullnameMutation();

  const onSubmit = async (data: any) => {
    try {
      const editUserFullnameResponse = await editUserFullname(data).unwrap();
      if (editUserFullnameResponse) {
        dispatch(
          setUserFullname({
            title: data.title,
            first_name: data.firstName,
            last_name: data.lastName,
          })
        );
        dispatch(toogleEditFullnameModal(false));
      }
    } catch (error: any) {
      setErrorMessage(error.data.message)
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(toogleEditFullnameModal(false))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="">
                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                          </div> */}
                    <div className="pb-2 text-center sm:mt-0  sm:text-left w-full border-b  ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-bold text-gray-900"
                      >
                        Edit Full Name
                      </Dialog.Title>
                    </div>

                    <div className="mt-4  ">
                      {/* form */}

                      <div className="space-y-8 divide-y divide-gray-200">
                        <div className="pt-8">
                          <div>
                            <Alert
                              type={"error"}
                              title={"An error occurred"}
                              show={editUserFullnameIsError}
                              description={errorMessage}
                              floating={false}
                            />
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Personal Information
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              These information may be visible on your public
                              profile.
                            </p>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                            <div className="sm:col-span-2">
                              <label
                                htmlFor="title"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Title
                              </label>
                              <div className="mt-1">
                                <select
                                  id="title"
                                  autoComplete="title"
                                  {...register("title")}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option value="" disabled>
                                    Please select
                                  </option>
                                  <option>Chief.</option>
                                  <option>Barr.</option>
                                  <option>Esq.</option>
                                  <option>Dr.</option>
                                  <option>Hon.</option>
                                  <option>Mr.</option>
                                  <option>Ms.</option>
                                  <option>Mrs.</option>
                                  <option>SAN.</option>
                                </select>
                                <span className="text-red-700 text-sm">
                                  {errors.title?.message}
                                </span>
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  {...register("firstName")}
                                  id="first name"
                                  autoComplete="firstName"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                <span className="text-red-700 text-sm">
                                  {errors.firstName?.message}
                                </span>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  {...register("lastName")}
                                  id="lastName"
                                  autoComplete="lastName"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              <span className="text-red-700 text-sm">
                                {errors.lastName?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex-col lg:space-x-6 lg:flex lg:flex-row space-x-0 justify-end">
                  <button
                    type="submit"
                    disabled={editUserFullnameIsLoading}
                    className="mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                  disabled:bg-green-400
                  "
                  >
                    {editUserFullnameIsLoading && (
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                    )}
                    Save
                  </button>
                  <button
                    onClick={() => dispatch(toogleEditFullnameModal(false))}
                    type="button"
                    className="mt-3 w-full  inline-flex justify-center py-2 px-4 border   shadow-sm text-sm font-medium rounded-md hover:text-white text-gray-700 bg-gray-100 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                  disabled:bg-gray-400   border-gray-300
                  "
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditFullName;
