import { Transition, Dialog } from '@headlessui/react';
import {FC,useRef, Fragment, useEffect}from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { editPhoneModal, toogleEditPhoneModal } from '../../store/modal/modalSlice';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useEditPhoneMutation } from '../../utils/api/user/userApi';
import { setUserPhone } from '../../store/user/userSlice';


const EditPhone:FC<{user?:UserType}> = ({user={details:{phone:''}}}) => {
  const open = useSelector(editPhoneModal);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

   // validation schema
   const schema = yup
   .object({
     phone: yup.string().required("Please enter your phone number"),
   })
   .required();
 const {
   register,
   handleSubmit,
   setValue,
   formState: { errors },
 } = useForm({
   resolver: yupResolver(schema),
 });

 useEffect(() => {
  setValue("phone", user.details.phone);
}, [setValue, user]);

const [editUserPhone,{isLoading:editUserPhoneIsLoading, isError:editUserPhoneIsError}]=useEditPhoneMutation()
 
const onSubmit = async (data: any) => {
  const editUserPhoneResponse = await editUserPhone(data).unwrap();
  if (editUserPhoneResponse) {
    dispatch(
      setUserPhone(data.phone)
    );
    dispatch(toogleEditPhoneModal(false));
  }
};
  return (
    <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed z-10 inset-0 overflow-y-auto"
      initialFocus={cancelButtonRef}
      onClose={() => dispatch(toogleEditPhoneModal(false))}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div> */}
                  <div className="pb-2 text-center sm:mt-0  sm:text-left w-full border-b  ">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Edit Phone Number
                    </Dialog.Title>
                  </div>

                  <div className="mt-4  ">
                    {/* form */}

                    <div className="space-y-8 divide-y divide-gray-200">
                      <div className="pt-8">
                        {/* <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Personal Information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            These information may be visible on your public
                            profile.
                          </p>
                        </div> */}
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                          
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone number
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                {...register("phone")}
                                id="phone"
                                autoComplete="phone"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              />
                              <span className="text-red-700 text-sm">
                                {errors.phone?.message}
                              </span>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex-col lg:space-x-6 lg:flex lg:flex-row space-x-0 justify-end">
                <button
                  type="submit"
                  disabled={editUserPhoneIsLoading}
                  className="mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                disabled:bg-green-400
                "
                >
                  {editUserPhoneIsLoading && (
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                  )}
                  Save
                </button>
                <button
                  onClick={() => dispatch(toogleEditPhoneModal(false))}
                  type="button"
                  className="mt-3 w-full  inline-flex justify-center py-2 px-4 border   shadow-sm text-sm font-medium rounded-md hover:text-white text-gray-700 bg-gray-100 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                disabled:bg-gray-400   border-gray-300
                "
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
  )
}

export default EditPhone