import { CheckCircleIcon } from "@heroicons/react/outline";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fullScreenModal, toogleFullScreenModal } from "../../store/modal/modalSlice";

const FullScreenOverlay: FC<{type:string, heading:string, description:string}> = ({type='success', heading="", description=""}) => {
  const dispatch = useDispatch();
  const color=type==='success'?'green':'red'; 
  const open = useSelector(fullScreenModal)
  return (
   <>
    {open && <div 
    className={`z-10 fixed top-0 left-0 w-full h-full bg-${color}-700 flex justify-center items-center text-white flex-col space-y-6`}>
      <CheckCircleIcon className="w-12 h-12" />
      <h3 className="text-lg leading-6 font-medium">{heading}</h3>
      <p className="mt-1 max-w-2xl text-sm  first-line:">
      {description}
      </p>
      <button
         onClick={() => dispatch(toogleFullScreenModal(false))}
        className={`
        ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${color}-900 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500
                disabled:bg-${color}-400
                `}
      >
        Okay
      </button>
    </div>}
   </>
  );
};

export default FullScreenOverlay;
