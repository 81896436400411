import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

const ProtectedRoute = () => {
    const user = useAuth()
    if (!user.id) {
        return <Navigate to="/" replace />
    }
    return <Outlet/>
}

export default ProtectedRoute