import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
import { RootState } from "../../store/store";

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/api",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const {user} = (getState() as RootState).user;
      const {token} = (getState() as RootState).user;
      headers.set("Accept", `application/json`);
      if (user && endpoint !== "refresh") {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  tagTypes:['Branches','BranchMembers', 'Events','Participants','Pricing','AllParticipants', 'AllUsers'],
  endpoints: () => ({}),
});
