import { FC } from "react"

import './Loading.css'
type LoadingProp = {
  show?: boolean
}
const Loading: FC<LoadingProp> = ({ show =false}) => {
  // const {global:{loading}} = useSelector((state:any) => state)

  return (
    <>
      {/* {show? <div className="loadingContainer">
          <div className="loader"></div> 
    </div>: loading && <div className="loadingContainer">
          <div className="loader"></div> 
    </div>} */}
      {show && <div className="loadingContainer">
        <div className="loader"></div>
      </div>}
    </>
  )
}

export default Loading
