import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteParticipant from "../../../../components/Modals/DeleteParticipant";
import EditParticipantDetails from "../../../../components/Modals/EditParticipantDetails";
import {
  toogleDeleteParticipantmodal,
  toogleEditParticipantModal,
} from "../../../../store/modal/modalSlice";
import { useGetAllUsersQuery } from "../../../../utils/api/admin/adminApi";
import ListPagination from "../../../../components/Navigation/ListPagination";
import Search from "../../../../components/Search/Search";

const UsersAll = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: usersData,
    isLoading: usersIsLoading,
    isFetching: usersIsFetching,
    isError: usersIsError,
  } = useGetAllUsersQuery(location.search);
  const [selectedParticipant, setSelectedParticipant] = useState({
    id: 0,
    first_name: "",
    email: "",
    uid: "",
    details: {},
  });
  const deleteParticipantHandler = (participant: UserType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleDeleteParticipantmodal(true));
  };

  const editProfileClickHandler = (participant: UserType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleEditParticipantModal(true));
  };

  return (
    <>
      <DeleteParticipant
        eventSlug={params.slug || ""}
        {...{ selectedParticipant }}
      />
      <EditParticipantDetails selectedParticipant={selectedParticipant} />
      <div className="relative">
        <header className="bg-white shadow-sm relative  ">
          <div className="max-w-7xl mx-auto py-4 px-6 sm:px-8 xl:px-0">
            <h1 className="text-lg leading-6 font-semibold text-gray-900">
              Users
            </h1>
          </div>
        </header>

        <main className="relative   bg-gray-100">
          <div className="max-w-7xl mx-auto py-6 px-6 sm:px-8 xl:px-0 relative  ">
            {/* Main 3 column grid */}
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <Search title={"Users"} />
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Position
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Actions
                            </th>
                            {/* <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                             
                          </th> */}
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 bg-white">
                          {/* loading */}
                          {usersIsLoading && (
                            <tr>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-200 animate-pulse "></div>
                                  <div className="ml-4 w-full">
                                    <div className="bg-gray-200 animate-pulse w-full h-6 my-1"></div>
                                    <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="bg-gray-200 animate-pulse w-full h-6"></div>
                              </td>

                              <td className="flex items-center h-24 w-full space-x-2 px-4">
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                                <div className="bg-gray-200 animate-pulse h-6 w-1/3"></div>
                              </td>
                            </tr>
                          )}
                          {/* error */}
                          {usersIsError && (
                            <tr>
                              <td className=" py-4 pl-4 pr-3 text-sm  ">
                                An error occured, try refreshing...
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}

                          {usersData && (
                            <>
                              {usersData.data.map((user: UserType) => (
                                <tr key={user.uid}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                      {usersIsFetching ? (
                                        <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 animate-pulse "></div>
                                      ) : (
                                        <div className="h-10 w-10 flex-shrink-0">
                                          <img
                                            className="h-10 w-10 rounded-full"
                                            src={
                                              user.details?.image
                                                ? user.details?.image.includes(
                                                    "https"
                                                  )
                                                  ? user.details?.image
                                                  : `${process.env.REACT_APP_IMAGE_URL}images/${user.details?.image}`
                                                : "/images/placeholder.jpg"
                                            }
                                            alt={""}
                                          />
                                        </div>
                                      )}

                                      <div className="ml-4 w-full">
                                        {usersIsFetching ? (
                                          <>
                                            <div className="bg-gray-300 animate-pulse w-full h-6 my-1"></div>
                                            <div className="bg-gray-300 animate-pulse w-full h-6"></div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="font-medium text-gray-900">
                                              {user.title} {user.first_name}{" "}
                                              {user.last_name}
                                            </div>
                                            <div className="text-gray-500">
                                              {user.email}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {usersIsFetching ? (
                                      <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
                                    ) : (
                                      <div className="text-gray-900">
                                        {user.details?.position}
                                      </div>
                                    )}
                                  </td>

                                  <td className="flex items-center justify-end space-x-3 lg:space-x-6   py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                                    {/* <button
                                        className="bg-green-900 text-white p-1 rounded-md h-full  w-6"
                                        onClick={viewPosterClickHandler(
                                          user.uid
                                        )}
                                      >
                                        <EyeIcon />
                                      </button> */}
                                    <Link
                                      className="bg-green-900 text-white p-1 rounded-md h-full  w-6"
                                      to={`/dashboard/admin/user/view?code=${user.uid}`}
                                    >
                                      <EyeIcon />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListPagination {...usersData} />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default UsersAll;
