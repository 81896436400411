import { baseApi } from "./../utils/api/baseApi";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userSlice from "./user/userSlice";
import navigationSlice from "./navigation/navigationSlice";
import branchSlice from "./branch/branchSlice";
import eventSlice from "./event/eventSlice";
import modalSlice from "./modal/modalSlice";
import notificationSlice from "./modal/notificationSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
//   blacklist:  [baseApi.reducerPath]
};

const persistedReducer = persistReducer(persistConfig, userSlice);

const store = configureStore({
  reducer: {
    user: persistedReducer,
    navigation: navigationSlice,
    branch: branchSlice,
    event: eventSlice,
    modal: modalSlice,
    notification:notificationSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
let persistor = persistStore(store)
export {store,persistor}
