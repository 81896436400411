import React from "react";
import "./Opener.css";
const Opener = () => {
  return (
    <div className="loading_middle">
      <div className="loading_contatiner">
        <svg id="loading_logo" viewBox="0 0 392 409" fill="none">
          <path
            d="M1.00006 121.247V113.463V113.46L1.00009 113.459L1.00032 113.436C1.00055 113.415 1.00096 113.383 1.00166 113.34C1.00307 113.253 1.00565 113.122 1.01033 112.95C1.01968 112.604 1.03742 112.091 1.07093 111.425C1.13796 110.094 1.26804 108.155 1.52016 105.735C2.02452 100.893 3.01662 94.1327 4.96662 86.4685C8.87036 71.1254 16.5917 52.2429 31.8404 37.8459C58.0539 13.0964 84.2341 4.40663 103.833 1.88723C113.639 0.626778 121.805 0.910025 127.511 1.50625C130.364 1.80433 132.601 2.18054 134.12 2.48076C134.78 2.61126 135.304 2.72738 135.684 2.81644V287.753L135.684 295.534V295.535L135.684 295.54L135.684 295.564C135.683 295.585 135.683 295.617 135.682 295.66C135.681 295.747 135.678 295.878 135.674 296.05C135.664 296.396 135.647 296.909 135.613 297.575C135.546 298.906 135.416 300.845 135.164 303.265C134.659 308.107 133.667 314.867 131.717 322.532C127.814 337.875 120.092 356.757 104.844 371.154C78.63 395.904 52.4498 404.593 32.8506 407.113C23.0452 408.373 14.8793 408.09 9.1729 407.494C6.31996 407.196 4.08282 406.819 2.56424 406.519C1.90413 406.389 1.3799 406.273 1 406.184V122.247H1.00006V121.247Z"
            stroke="green"
            strokeWidth="2"
          />
          <path
            d="M264.445 407.684H264.444L257.91 407.684L247.378 407.684L130.195 407.684C130.106 407.304 129.99 406.78 129.859 406.12C129.559 404.601 129.183 402.364 128.885 399.511C128.288 393.805 128.005 385.639 129.266 375.833C131.785 356.234 140.475 330.054 165.224 303.84C179.621 288.592 198.504 280.87 213.847 276.967C221.511 275.017 228.271 274.025 233.113 273.52C235.533 273.268 237.473 273.138 238.804 273.071C239.469 273.037 239.983 273.02 240.328 273.01C240.501 273.006 240.631 273.003 240.718 273.002C240.761 273.001 240.794 273.001 240.815 273L240.838 273L240.838 273L240.843 273L240.844 273L247.378 273L257.624 273L257.91 273L375.093 273C375.182 273.38 375.299 273.904 375.429 274.564C375.729 276.083 376.105 278.32 376.404 281.173C377 286.879 377.283 295.045 376.023 304.851C373.503 324.45 364.813 350.63 340.064 376.843C325.667 392.092 306.784 399.814 291.441 403.717C283.777 405.667 277.017 406.659 272.175 407.164C269.755 407.416 267.816 407.546 266.484 407.613C265.819 407.646 265.306 407.664 264.96 407.674C264.788 407.678 264.657 407.681 264.57 407.682C264.555 407.682 264.54 407.683 264.527 407.683C264.505 407.683 264.487 407.683 264.474 407.684L264.45 407.684L264.45 407.684L264.445 407.684Z"
            stroke="green"
            strokeWidth="2"
          />
          <path
            d="M156.976 104.485L204.74 50.6084C216.098 37.7972 235.69 36.6189 248.501 47.9765L366.73 152.791C379.541 164.148 380.72 183.741 369.362 196.552L321.598 250.429C310.241 263.24 290.648 264.418 277.837 253.06L159.608 148.246C146.797 136.889 145.619 117.296 156.976 104.485Z"
            stroke="red"
            strokeWidth="2"
          />
        </svg>
      </div>
    </div>
  );
};

export default Opener;
