import { FC } from "react"
type AlertProps = {
    type: 'error' | 'normal' | 'success' | 'warning',
    title: string|undefined,
    description?: string
    show: boolean,
    floating?: boolean
}
const Alert: FC<AlertProps> = ({ type = 'normal', title, description, show=false, floating=true,...props }) => {
    let bgColor
    let textColor
    switch (type) {
        case 'error':
            bgColor = 'bg-red-100';
            textColor = 'text-red-900'
            break;
        case 'normal':
            bgColor = 'bg-blue-100';
            textColor = 'text-blue-900'
            break;
        case 'success':
            bgColor = 'bg-green-100';
            textColor = 'text-green-900'
            break;
        case 'warning':
            bgColor = 'bg-amber-100';
            textColor = 'text-amber-900'
            break;

    }
    return (
        <>
        {show&&<div className={`rounded-md ${bgColor} p-4 ${floating && 'absolute'}  `}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {/* <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" /> */}
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm ${textColor} font-bold`}>{title}</h3>
                    <div className={`mt-2 text-sm ${textColor}`}>
                        <p>
                        {Array.isArray(description) ? description.map((item, index) => <p key={index}>{item}</p>) : description && <p>{description}</p>}
                        </p>
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default Alert