export const ButtonSize = {
  xs: `py-0.5 px-1.5 text-xs leading-6`,
  sm: `py-1.5 px-2.5 text-sm font-bold  leading-6`,
  md: `py-2 px-4 text-sm  font-bold  leading-6`,
  base: `py-2 px-4 text-base  font-bold  leading-6`,
  lg: `py-3 px-6 text-lg   font-bold leading-6 `,
};

export const BackgroundColor = {
  primary: `cursor-pointer inline-flex justify-center items-center text-white bg-primary hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-700 focus:ring-yellow-400 relative  border border-transparent disabled:bg-gray-200 disabled:cursor-auto`,
  secondary: `cursor-pointer inline-flex justify-center items-center text-white bg-secondary hover:bg-secondary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-secondary-400 relative  border border-transparent  disabled:bg-secondary-200 disabled:cursor-auto` ,
  white: `cursor-pointer inline-flex justify-center items-center shadow border border-gray-200 text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-gray-200 relative  border border-transparent disabled:bg-gray-200 disabled:cursor-auto`,
  
  tertiary: `cursor-pointer inline-flex justify-center items-center text-white bg-tertiary-dark  hover:bg-tertiary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-tertiary-700 focus:ring-primary-400  relative  border border-transparent disabled:bg-tertiary-200 disabled:cursor-auto`,
};
export const TextColor = {
  primary: `inline-flex items-center  border-0 border-primary-700 text-on-primary bg-transparent relative disabled:text-gray-300 disabled:border-gray-400`,

  secondary: `inline-flex items-center border-0 border-secondary-700 text-secondary-700 bg-transparent  relative disabled:text-gray-300 disabled:border-gray-400`,

  white: `inline-flex items-center border-0 border-gray-200 ext-gray-900 bg-transparent  relative disabled:text-gray-300 disabled:border-gray-400`,
 
  tertiary: `inline-flex items-center border-0 border-tertiary-700 text-tertiary-700  bg-transparent  relative disabled:text-gray-300 disabled:border-gray-400`,
};

export const Rounded = {
  sm: "rounded-sm",
  md: "rounded-md",
  base: "rounded",
  full: "rounded-full",
};

export const Width = {
  auto: "w-auto",
  full: "w-full",
};
