import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getUserImage, getUserToken, selectCurrentUser } from "../store/user/userSlice"

export const useAuth = ()=>{
    const user = useSelector(selectCurrentUser)
    return useMemo(()=>user,[user])
}

export const useToken=()=>{
    const token = useSelector(getUserToken);
    return useMemo(()=>token,[token])
}

export const useImage = ()=>{
    const image = useSelector(getUserImage)
     
    return useMemo(()=>image,[image])
}