import {  useLocation, useNavigate } from "react-router-dom";
import NewButton from "../Button/NewButton";
 
 

const ListPagination = ({
  current_page,
  last_page,
}: Partial<PaginationType>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const nextPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage + 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const previousPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage - 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  return (
    <nav
      className="bg-white px-4 py-3 my-6 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{`Page ${current_page} of ${last_page}`}</span>
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end space-x-2">
        <NewButton
          className="bg-red-700 disabled:bg-gray-200"
          disabled={(current_page || 1) <= 1}
          onClick={() => {
            previousPageHandler();
          }}
        >
          Previous
        </NewButton>
        <NewButton
          className="bg-green-700 disabled:bg-gray-200"
          disabled={(current_page || 1) >= last_page!}
          onClick={() => {
            nextPageHandler();
          }}
        >
          Next
        </NewButton>
      </div>
    </nav>
  );
};

export default ListPagination;
