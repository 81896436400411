import { FC, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  editParticipantDetailModal,
  toogleEditParticipantModal,
} from "../../store/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEditParticipantMutation } from "../../utils/api/event/eventApi";
const EditParticipantDetails: FC<{ selectedParticipant: ParticipantType }> = ({
  selectedParticipant,
}) => {
  console.log(selectedParticipant);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const open = useSelector(editParticipantDetailModal);
  // validation schema
  const schema = yup
    .object({
      title: yup.string().required("Please select a title"),
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(()=>{
    setValue("title",selectedParticipant.title)
    setValue("firstName",selectedParticipant.first_name)
    setValue("lastName",selectedParticipant.last_name)
    setValue("postition",selectedParticipant.details?.position)
  },[selectedParticipant.details?.position, selectedParticipant.first_name, selectedParticipant.last_name, selectedParticipant.title, setValue])
 
  const [
    editParticipant,
    { isLoading: editParticipantIsLoading, error: editParticipantError },
  ] = useEditParticipantMutation();
  const onSubmit = async (data: any) => {
    console.log(data);
    const editParticipantDetailResponse = await editParticipant(data).unwrap();
    console.log(editParticipantDetailResponse);
    // dispatch(setUser(createProfileResponse))
    // TODO dissmisable notifications
    // navigate('/../dashboard')
  };
  let err;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(toogleEditParticipantModal(false))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                          </div> */}
                  <div className="pb-2 text-center sm:mt-0  sm:text-left w-full border-b  ">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Edit {selectedParticipant.first_name}{" "}
                      {selectedParticipant.last_name || ""} Details
                    </Dialog.Title>
                  </div>

                  <div className="mt-4  ">
                    {/* form */}
                    <form
                      className="space-y-8 divide-y divide-gray-200"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div className="pt-8">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Personal Information
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              These information may be visible on your public
                              profile.
                            </p>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                            <div className="sm:col-span-2">
                              <label
                                htmlFor="title"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Title
                              </label>
                              <div className="mt-1">
                                <select
                                  id="title"
                                  autoComplete="title"
                                  {...register("title")}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option value="" disabled>
                                    Please select
                                  </option>
                                  <option>Barr.</option>
                                  <option>Esq.</option>
                                  <option>Dr.</option>
                                  <option>Hon.</option>
                                  <option>Mr.</option>
                                  <option>Ms.</option>
                                  <option>Mrs.</option>
                                  <option>SAN.</option>
                                </select>
                                <span className="text-red-700 text-sm">
                                  {errors.title?.message}
                                </span>
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  value={selectedParticipant.first_name}
                                  {...register("firstName")}
                                  id="first name"
                                  autoComplete="firstName"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                <span className="text-red-700 text-sm">
                                  {errors.firstName?.message}
                                </span>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  {...register("lastName")}
                                  id="lastName"
                                  autoComplete="lastName"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              <span className="text-red-700 text-sm">
                                {errors.lastName?.message}
                              </span>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="position"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Position
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  {...register("position")}
                                  id="position"
                                  autoComplete="position"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              {/* <p className="mt-1 text-sm text-gray-500">if just a member of branch, then leave this blank</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  disabled={editParticipantIsLoading}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                  disabled:bg-green-400
                  "
                >
                  {editParticipantIsLoading && (
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                  )}
                  Save
                </button>
                <button
                  onClick={() => dispatch(toogleEditParticipantModal(false))}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditParticipantDetails;
