import {StatusOfflineIcon } from "@heroicons/react/solid";
import { FC } from "react";

const ErrorLoading: FC<{
  refetch?: () => void;
}> = ({refetch}) => {
  return (
    <div className="text-center grid place-items-center h-screen w-full">
      <div>
        <StatusOfflineIcon className=" h-20 w-20 text-gray-900   mx-auto" />
        <h3 className="mt-2 text-lg font-bold text-gray-900">
          Connection error
        </h3>
        <p className="mt-1 text-base text-gray-700">
          There seems to be a problem with your connection
        </p>
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <StatusOfflineIcon
            onClick={refetch}
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            />
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
};
export default ErrorLoading;
