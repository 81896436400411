import Features from "../../components/Features/Features";
import Hero from "../../components/Hero/Hero";
import Pricing from "../../components/Pricing/Pricing";

const Home = () => {
  return (
    <>
      <main>
        <Hero />
        <Features />
        {/* <Pricing /> */}
      </main>
    </>
  );
};

export default Home;
