import React, { FC, useEffect, useState } from "react";
/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from "@heroicons/react/solid";
import { ExclamationCircleIcon, PencilAltIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import {
  useGetEventQuery,
  useRegisterEventQuery,
  useRegisterUserForEventMutation,
} from "../../../../utils/api/event/eventApi";
import ErrorLoading from "../../../../components/Loading/ErrorLoading";
import Opener from "../../../../components/Loading/Opener";
import currency from "currency.js";
import PaymentWithPaystack from "../../../../components/Modals/PaymentWithPaystack";
import FullScreenOverlay from "../../../../components/Modals/FullScreenOverlay";
import { useDispatch } from "react-redux";
import {
  toogleEditFullnameModal,
  toogleEditPhoneModal,
  toogleFullScreenModal,
} from "../../../../store/modal/modalSlice";
import ProfileImage from "../../../../components/ProfileImage/ProfileImage";
import EditFullName from "../../../../components/Modals/EditFullName";
import EditPhone from "../../../../components/Modals/EditPhone";
import RoleComponent from "../../../../components/RoleComponent";

const EventForm: FC = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [userRegister, setUserRegister] = useState({
    amount: 0,
    plan_id: 0,
    event_id: 0,
    designation_id: 0,
  });
  const [paymentOverlay] = useState({
    show: false,
    type: "success",
    heading: "Registration complete",
    description: "All done, you have successfully registered for the event",
  });
  const [paymentMessage, setPaymentMessage] = useState("");
  const user: UserType = useAuth();

  // REQUESTS
  const {
    data: eventData,
    isError: eventIsError,
    isLoading: eventIsLoading,
  } = useGetEventQuery({
    slug: params.slug || "",
    type: "register",
  }); 
  const {
    data: eventUserData,
    refetch: eventDataRefetch,
    isError: eventUserDataIsError,
    isLoading: eventUserDataLoading,
    isFetching: eventUserDataFetching,
  } = useRegisterEventQuery({
    slug: params.slug || "",
  });
  
  const [
    registerUserWithDetails,
    {
      isLoading: registerUserWithDetailsLoading,
    },
  ] = useRegisterUserForEventMutation();

  // validation schema
  const schema = yup
    .object({
      designation: yup.string().required("Please select a designation").nullable(),
      // plan: yup.string().required("Please select a payment package").nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      designation: "",
      plan: "",
    },
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (eventUserData) {
      setValue("designation", eventUserData?.enroll?.designation_id);
      setValue("plan", eventUserData?.enroll?.plan_id);
    }
  }, [eventUserData, setValue]);

  // HANDLE BUTTON CLICKS
  // handle payment
  const paymentResponse = async (ref: PaymentRef) => {
    const { status } = ref;
    if (status === "success") {
      const data: PaymentRecord = {
        amount: userRegister.amount,
        payment_ref: ref.reference,
        payment_type: "paystack",
        plan_id: userRegister.plan_id,
        user_id: user.id,
        event_id: userRegister.event_id,
        designation_id: userRegister.designation_id,
        branch_id: null,
      };
      const registerUserWithDetailsResponse = await registerUserWithDetails(
        data
      ).unwrap();
      dispatch(toogleFullScreenModal(true));
      eventDataRefetch();
    }
  };

  const eventRegistrationFormSubmitHandler = async (data: any) => {
    let registerDetails = {};
    // check if payment is required
    if (eventData.payment_plans.length > 0) {
      registerDetails = {
        plan_id: data.plan,
        user_id: user.id,
        event_id: userRegister.event_id,
        designation_id: data.designation,
        branch_id: null,
      };

      if (userRegister.amount <= 0) {
        const registerUserWithDetailsResponse = await registerUserWithDetails(
          registerDetails
        ).unwrap();
        dispatch(toogleFullScreenModal(true));
        eventDataRefetch(); 
        return;
      }
 
    } else {
      registerDetails = {
        plan_id: null,
        user_id: user.id,
        event_id: eventData.id,
        designation_id: data.designation? data.designation : null,
        branch_id: null,
      };
      // proceed to save record and register user
      const registerUserWithDetailsResponse = await registerUserWithDetails(
        registerDetails
      ).unwrap();
      // console.log(registerUserWithDetailsResponse);
      dispatch(toogleFullScreenModal(true));
      eventDataRefetch();
      return;
    }
  };

  const showEventParticipationCost = (paymentPlanId: string) => {
    if (eventData.payment_plans.length > 0) {
      const choosenPlan = eventData.payment_plans.filter((element: any) => {
        return element.id == paymentPlanId;
      }); 
      setUserRegister({
        ...userRegister,
        amount: choosenPlan[0].amount,
        plan_id: choosenPlan[0].id,
        event_id: choosenPlan[0].event_id,
      });
      setPaymentMessage(
        `You are required to pay  ${currency(choosenPlan[0].amount, {
          separator: ",",
          symbol: "₦",
        }).format()} to complete registration`
      );
    }
  };
  if (eventIsError || eventUserDataIsError) return <ErrorLoading />;
  if (eventIsLoading || eventUserDataLoading) return <Opener />;

  return (
    <>
      <EditFullName user={user} />
      <EditPhone user={user} />
      <div className="relative">
        <header className="bg-white shadow-sm relative  ">
          <div className="max-w-7xl mx-auto py-4 px-4  sm:px-8 xl:px-0">
            <h1 className="text-lg leading-6 font-semibold text-gray-900">
              Dashboard
            </h1>
          </div>
        </header>

        <main className="relative   bg-gray-100  lg:px-0">
          <div className="max-w-7xl mx-auto py-6 px-6   relative   ">
            {/* Main 3 column grid */}
            <div className="flex  lg:space-x-4  lg:flex-row flex-col-reverse items-start">
              {/* Left column */}

              <div className="grid grid-cols-1 gap-4 lg:col-span-2 w-full lg:w-3/4 relative overflow-hidden rounded-md">
                <FullScreenOverlay
                  type={paymentOverlay.type}
                  heading={paymentOverlay.heading}
                  description={paymentOverlay.description}
                />

 
             <div className="flex w-full">
             <RoleComponent role={user.role?.role||'user'} allowedRoles={['admin']}>
                   <div className="flex w-full space-x-1 justify-end">
                      <Link
       
                        to={`../document/all?event=${eventData.slug}&type=tag`}
                        className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-blue-400"
                      >
                        {/* <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div> */}
                        Print all Tags
                      </Link>
                     
                    </div>
                   </RoleComponent>
               
                   <RoleComponent role={user.role?.role||'user'} allowedRoles={['manager','admin']}>
                   <div className="flex w-full space-x-1 justify-end">
                      <Link
                        to={`../event/${eventData.slug}/participants`}
                        className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400"
                      >
                        {/* <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div> */}
                        View participants
                      </Link>
                     
                    </div>
                   </RoleComponent>
               
             </div>
                {eventUserData?.enroll?.status === 1 && (
                  <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="py-2">Choose option to download</h3>
                    <div className="flex w-full space-x-1 justify-between lg:justify-center">
                      <Link
                        to={`../document?event=${eventData.slug}&type=poster`}
                        className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400"
                      >
                        {/* <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div> */}
                        Event Poster
                      </Link>
                      {/* {eventData?.templates.find((template:any)=>{return template.type==='poster' })} */}
                      
                      <Link
                        to={`../document?event=${eventData.slug}&type=tag`}
                        className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
                      >
                        {/* <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div> */}
                      Tag
                      </Link>
                      {/* <button className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:bg-amber-400">
                      
                        Documents
                      </button> */}
                    </div>
                  </div>
                )}

                <div className="bg-white shadow-md rounded-lg p-6">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Registrant Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Personal details
                    </p>
                  </div>

                  <form
                    className="space-y-8 divide-y divide-gray-200"
                    onSubmit={handleSubmit(eventRegistrationFormSubmitHandler)}
                  >
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-600">
                            <p>Image</p>

                            <span className="text-xs italic font-medium text-gray-500">
                              click to change image
                            </span>
                          </dt>
                          <dd className="mt-1  text-left">
                            <ProfileImage />
                          </dd>
                        </div>
                        <div className="flex justify-between w-full items-center relative">
                          <div className="w-full bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-bold text-gray-600 flex justify-between">
                              <span>Full name</span>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <span className="capitalize">
                                {user.title} {user.last_name} {user.first_name}
                              </span>
                            </dd>
                          </div>
                          <PencilAltIcon
                            onClick={() =>
                              dispatch(toogleEditFullnameModal(true))
                            }
                            className="lg:absolute right-8  h-6 w-6 text-gray-700 cursor-pointer p-px"
                          />
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-600">
                            Email address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {user.email}
                          </dd>
                        </div>
                        <div className="flex justify-between w-full items-center relative">
                          <div className="w-full bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-bold text-gray-600 flex justify-between">
                              <span>Phone Number</span>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <span className="capitalize">
                                {user.details.phone || "No Phone Number yet"}
                              </span>
                            </dd>
                          </div>
                          <PencilAltIcon
                            onClick={() => dispatch(toogleEditPhoneModal(true))}
                            className="lg:absolute right-8  h-6 w-6 text-gray-700 cursor-pointer p-px"
                          />
                        </div>

                        {eventData.designations.length > 0 && (
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-bold text-gray-600">
                              Designation
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <div className="sm:col-span-2">
                                <div className="mt-1">
                                  <select
                                    id="designation"
                                    disabled={
                                      eventUserData?.enroll?.status === 1
                                    }
                                    {...register("designation")}
                                    onChange={(e) => {
                                      setUserRegister({
                                        ...userRegister,
                                        designation_id: Number(e.target.value),
                                      });
                                    }}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:text-gray-400 disabled:bg-gray-50"
                                  >
                                    <option value="">Please select</option>
                                    {eventData.designations.map(
                                      (designation: any) => (
                                        <option
                                          key={designation.id}
                                          value={designation.id}
                                        >
                                          {designation.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="text-red-700 text-sm">
                                    {errors.designation?.message}
                                  </span>
                                </div>
                              </div>
                            </dd>
                          </div>
                        )}
                        {eventData.payment_plans.length > 0 && (
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-bold text-gray-600">
                              Payment
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <div className="sm:col-span-2">
                                <div className="mt-1">
                                  <select
                                    id="plan"
                                    disabled={
                                      eventUserData?.enroll?.status === 1
                                    }
                                    {...register("plan")}
                                    onChange={(e) => {
                                      showEventParticipationCost(
                                        e.target.value
                                      );
                                    }}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:text-gray-400 disabled:bg-gray-50"
                                  >
                                    <option value="" disabled>
                                      Please select
                                    </option>
                                    {eventData.payment_plans.map(
                                      (payment_plan: any) => (
                                        <option
                                          key={payment_plan.id}
                                          value={payment_plan.id}
                                        >
                                          {payment_plan.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="text-gray-600 text-xsm  ">
                                    {paymentMessage}
                                  </span>
                                  <br />
                                  <span className="text-red-700 text-sm">
                                    {errors.plan?.message}
                                  </span>
                                </div>
                              </div>
                            </dd>
                          </div>
                        )}
                      </dl>
                    </div>

                    {/* form */}
                    {eventUserData?.enroll?.status !== 1 ? (
                      <div className="pt-5">
                        <div className="flex justify-end">
                          <Link
                            to="/dashboard"
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </Link>

                          {userRegister.amount > 0 ? (
                            <PaymentWithPaystack
                              amount={userRegister.amount * 100}
                              email={user.email}
                              paymentResponse={paymentResponse}
                            />
                          ) : (
                            <button
                              type="submit"
                              disabled={registerUserWithDetailsLoading}
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                disabled:bg-green-400
                "
                            >
                              {registerUserWithDetailsLoading && (
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                              )}
                              Complete Registration
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>

              {/* Right column */}
              <div className="lg:flex  w-full lg:w-1/4 flex-col mb-4">
                {/* progress */}
                <div className="bg-white shadow-md p-6 rounded-lg">
                  <div className="px-4 py-5 sm:px-6 divide-y divide-gray-500">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Progress
                    </h3>
                  </div>

                  <div className="flow-root ">
                    <ul className="-mb-8 lg:block flex w-full justify-between">
                      <li>
                        <div className="relative pb-8">
                          <span
                            className="absolute top-4 left-4 -ml-px h-0.5 w-full lg:w-0.5 lg:h-full bg-gray-200"
                            aria-hidden="true"
                          />
                          <div className="relative items-center flex flex-col lg:flex-row lg:space-x-3 text-center lg:text-left">
                            <div>
                              <span
                                className={
                                  user.details.image
                                    ? `bg-green-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                    : `bg-amber-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                }
                              >
                                {eventUserDataFetching ? (
                                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                                ) : (
                                  <>
                                    {user.details.image ? (
                                      <CheckCircleIcon
                                        className="h-5 w-5 text-white"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ExclamationCircleIcon
                                        className="h-5 w-5 text-white"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                {eventUserDataFetching ? (
                                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                                ) : (
                                  <>
                                    <p className="text-sm text-gray-700">
                                      Upload Image
                                    </p>
                                    <span className="text-xs text-gray-500">
                                      {user.details.image
                                        ? "Uploaded"
                                        : "None Uploaded"}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="relative pb-8">
                          <span
                            className="absolute top-4 left-4 -ml-px h-0.5 w-full lg:w-0.5 lg:h-full bg-gray-200"
                            aria-hidden="true"
                          />
                          <div className="relative items-center flex flex-col lg:flex-row lg:space-x-3 text-center lg:text-left">
                            <div>
                              <span
                                className={
                                  eventUserData?.enroll?.status === 1
                                    ? `bg-green-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                    : `bg-amber-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                }
                              >
                                {eventUserDataFetching ? (
                                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                                ) : (
                                  <>
                                    {eventUserData?.enroll?.status ? (
                                      <CheckCircleIcon
                                        className="h-5 w-5 text-white"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ExclamationCircleIcon
                                        className="h-5 w-5 text-white"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              {eventUserDataFetching ? (
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                              ) : (
                                <div>
                                  <p className="text-sm text-gray-700">
                                    Register For Event
                                  </p>
                                  <span className="text-xs text-gray-500">
                                    {eventUserData?.enroll?.status === 1
                                      ? "Completed"
                                      : "In progress"}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* make payment */}
                      {eventData.payment_plans.length > 0 && (
                        <li>
                          <div className="relative pb-8">
                            <div className="relative items-center flex flex-col lg:flex-row lg:space-x-3 text-center lg:text-left">
                              <div>
                                <span
                                  className={
                                    eventUserData?.enroll?.status === 1
                                      ? `bg-green-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                      : `bg-amber-600 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`
                                  }
                                >
                                  {eventUserDataFetching ? (
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                                  ) : (
                                    <>
                                      {eventUserData?.enroll?.status ? (
                                        <CheckCircleIcon
                                          className="h-5 w-5 text-white"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ExclamationCircleIcon
                                          className="h-5 w-5 text-white"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                {eventUserDataFetching ? (
                                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>
                                ) : (
                                  <div>
                                    <p className="text-sm text-gray-700">
                                      Make Payment
                                    </p>
                                    <span className="text-xs text-gray-500">
                                      {eventUserData?.enroll?.status === 1
                                        ? "Completed"
                                        : "In progress"}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default EventForm;
