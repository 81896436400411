import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";
import Notification from "../../components/Notification/Notification";
 

const Base = () => {
  return (
    <>
    
      <Notification type={"normal"} title={""} />
      <Navigation />
    
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 ">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Base;
