import {
  LinkIcon,
  DuplicateIcon,
  CreditCardIcon,
  BookOpenIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Automate design generation",
    description:
      "Skip the hassle of designing event tags for each participants. Automate the tag and poster generation for your event",
    icon: DuplicateIcon,
  },
  {
    name: "Event management",
    description:
      "Securely view, edit, manage the data of participants registered to your Event with ease.",
    icon: BookOpenIcon,
  },
  {
    name: "Smart Cards",
    description:
      "Unique and verifiable ID cards for members of your branch, that can be scanned with a smartphone to verify a lawyer's public profile.",
    icon: CreditCardIcon,
  },
  {
    name: "Easy Integration",
    description:
      "Lawrify does not need you to have a website of your own, but if you do, it can easily integrate into your existing website.",
    icon: LinkIcon,
  },
];
const Features = () => {
  return (
    <section className="py-24 bg-white dark:bg-gray-900 dark:text-gray-50 text-gray-700">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 xl:px-0">
        <div className="lg:text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-50 sm:text-center">
            Features
          </h2>
          <p className="mt-5 text-xl text-gray-500 sm:text-center dark:text-gray-200">
            A better way to manage your members and events
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-bold text-gray-900 dark:text-gray-50">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base ">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default Features;
