import { FC, SetStateAction, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth, useImage } from "../../hooks/useAuth";
import { toogleImageModal } from "../../store/modal/modalSlice";
import { getUserImage } from "../../store/user/userSlice";
import {
  useGetUserWithCodeQuery,
  useUploadImageMutation,
} from "../../utils/api/user/userApi";
import CropEasy from "../Modals/CropEasy";
import UploadImage from "../Modals/UploadImage";
import { QRCodeSVG } from "qrcode.react";
import ErrorLoading from "../Loading/ErrorLoading";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import { toPng } from "html-to-image";
import ProfileImage from "../ProfileImage/ProfileImage";

interface PreviewCardProps {
  uid?: string | null;
  userData?: UserType;
  fromLink?: boolean;
  qrCodeRef?:any,
  userFullName?:any,
    isAdmin?:boolean
}

const PreviewCard: FC<PreviewCardProps> = ({
  uid,
  fromLink = false,
  qrCodeRef = null,
  userFullName=null,
    isAdmin=false,
  ...props
}) => {
  let userImage;
  let user: UserType;
  const imageFromStore = useImage();
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    data: userCodeData,
    isLoading: userCodeIsLoading,
    isFetching: userCodeIsFetching,
    isError: userCodeIsError,
  } = useGetUserWithCodeQuery(uid);
  console.log(userCodeData);
  const userFromStore = useAuth();
  if (fromLink && userCodeIsError) return <ErrorLoading />;
  if (fromLink && userCodeIsLoading) return <>Loading</>;
  if (fromLink && userCodeData) {
    user = userCodeData;
    userImage = user.details.image;

  } else {
    user = userFromStore;
    userImage = imageFromStore;
  }


  return (
    <>
      <section className="">
        <div className="rounded-lg bg-white overflow-hidden shadow relative">
          {!fromLink && !user.branch && (
            <div className="w-full h-full bg-gray-400/80 absolute z-10 text-white flex justify-center items-center">
              <Link
                to={"./profile"}
                className="px-2 py bg-gray-800 rounded-full"
              >
                Click to add details
              </Link>
            </div>
          )}
          <div>
            {/* header */}
            <div className="relative w-full object-cover h-60 bg-gradient-to-b from-[#104F00] to-[#111800]">
              <div className="relative flex flex-col items-center w-full h-full  lg:p-6 p-4">
                <img
                  className=" object-cover h-20 w-20 rounded-full mb-2 "
                  src={user.branch?.logo || "/images/placeholder.jpg"}
                  alt=""
                />
                <p className="text-xl text-white uppercase font-bold leading-loose text-center">
                  Nigerian Bar Association
                </p>
                <p className="text-lg text-white uppercase font-bold leading-5">
                  {user.branch?.name}
                </p>
                <p className="font-medium text-white uppercase">
                  ({user.branch?.nickname})
                </p>
              </div>
            </div>

            {/* bio */}
            <div className="max-w-5xl mx-auto px-4  relative">
              <div className="-mt-8">
                <div className="flex justify-center w-full relative">

                  <ProfileImage  size={'h-40 w-40'} otherUserImage={isAdmin? userImage:''} isAdmin={isAdmin}/>
                  {/*<img*/}
                  {/*  // onClick={handleImageClicked}*/}
                  {/*  className="h-40 w-40  rounded-full ring-2 ring-white"*/}
                  {/*  src={*/}
                  {/*    userImage*/}
                  {/*      ? userImage.includes("https")*/}
                  {/*        ? userImage*/}
                  {/*        : `${process.env.REACT_APP_IMAGE_URL}images/${userImage}`*/}
                  {/*      : "/images/placeholder.jpg"*/}
                  {/*  }*/}
                  {/*  alt=""*/}
                  {/*/>*/}

                </div>
              </div>
              <div className="mt-2 text-gray-700 text-center uppercase">
                <h1 className="text-2xl font-bold  text-green-900   leading-7"
                ref={userFullName}
                >
                  {user.first_name} {user.last_name}{" "}
                </h1>
                <p className=" font-bold  ">{user.details.position}</p>
                <p className="text-sm italic"> </p>
              </div>
            </div>

            {/* details */}
            <div className="flex justify-between w-full p-4 space-x-2 mt-6">
              <div className="w-1/2">
                <ul className="divide-y divide-gray-400 w-full font-medium">
                  <li className="py-1 font-bold">
                    <p className="text-sm  text-green-900">Year Of Call</p>
                    <p className="text-sm text-gray-700">{user.details.year}</p>
                  </li>
                  <li className="py-1 font-bold">
                    <p className="text-sm  text-green-900">Law Firm</p>
                    <p className="text-sm text-gray-700">
                      {user.details.law_firm}
                    </p>
                  </li>
                  <li className="py-1 font-bold">
                    <p className="text-sm  text-green-900">SCN</p>
                    <p className="text-sm text-gray-700">
                      {user.details.enrolment}
                    </p>
                  </li>
                </ul>
              </div>

              {/* qr code */}
              <div className="rounded-lg relative w-1/2 items-center justify-center"
               ref={qrCodeRef}
              >
                {/* <p className="font-bold">Branch Code: </p> */}

                {/* <p className="text-white text-xs font-bold mb-2">Branch Code: 020</p> */}
                <QRCodeSVG
                  value={`https://lawrify.com.ng/profile?code=${user.uid}`}
                  level="H"
                  className="w-32 h-32 mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PreviewCard;
