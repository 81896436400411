import React, { ChangeEvent, FormEvent, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import Button from "../Button/Button";
const Search = ({ title = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchParams = new URLSearchParams(location.search);
  const searchSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const search = e.currentTarget.search.value as string;
    // searchParams.set("search", search);
    // navigate(`${location.pathname}?${searchParams.toString().trim()}`);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ search: search.trim() })}`,
    };
    navigate(options);
  };
  return (
    <form onSubmit={(e: FormEvent<HTMLFormElement>) => searchSubmitHandler(e)}>
      <label
        htmlFor="search"
        className="sr-only block text-sm font-medium text-gray-700"
      >
        Search
      </label>
      <div className="mt-1 relative rounded-md shadow-sm  ">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="text-gray-500 sm:text-sm h-6" />
          {/* <SearchIcon className="text-gray-500 sm:text-sm h-6" /> */}
        </div>
        <input
          type="text"
          name="search"
          id="search"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchQuery(e.target.value)
          }
          className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-12 pr-12  py-4 sm:text-sm border-gray-300 rounded-md"
          placeholder={`Search ${title}`}
        />
        <div className="absolute inset-y-2 right-2 flex items-center space-x-2 bg-gray-100 h-10 rounded ">
          {location.search && (
            <Button
              color={"primary"}
              onClick={() => {
                navigate(location.pathname);
              }}
      
            >
              Cancel
            </Button>
          )}
          <Button
            color={"primary"}
            disabled={searchQuery.length < 3}
    
          >
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Search;
