import { createSlice } from "@reduxjs/toolkit"
 
import { RootState } from "../store"

const initialState={
    uploadImage:false,
    deleteParticipant:false,
    editParticipantDetail:false,
    editFullname:false,
    editPhone:false,
    verifyUser:false,
    fullScreen:false, 
}
export const modalSlice = createSlice({
    name:'modal',
    initialState,
    reducers:{
        toogleImageModal:(state, action)=>{state.uploadImage=action.payload},
        toogleDeleteParticipantmodal:(state, action)=>{state.deleteParticipant = action.payload},
        toogleEditParticipantModal:(state, action)=> {state.editParticipantDetail= action.payload},
        toogleEditFullnameModal:(state,action)=>{state.editFullname=action.payload},
        toogleEditPhoneModal:(state,action)=>{state.editPhone=action.payload},
        toogleVerifyUserModal:(state, action)=>{state.verifyUser=action.payload},
        toogleFullScreenModal:(state, action)=>{state.fullScreen=action.payload},
        
    }
})
export const {toogleImageModal, toogleDeleteParticipantmodal, toogleEditFullnameModal,toogleEditParticipantModal,toogleVerifyUserModal, toogleFullScreenModal, toogleEditPhoneModal}= modalSlice.actions
export default modalSlice.reducer
export const imageModal = (state:RootState)=>state.modal.uploadImage
export const deleteParticipantModal= (state: RootState) =>state.modal.deleteParticipant
export const editParticipantDetailModal= (state: RootState) =>state.modal.editParticipantDetail
export const verifyUserModal = (state: RootState)=>state.modal.verifyUser
export const fullScreenModal = (state: RootState) =>state.modal.fullScreen
export const editFullnameModal = (state: RootState)=>state.modal.editFullname
export const editPhoneModal = (state: RootState)=>state.modal.editPhone