import { FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  deleteParticipantModal,
  toogleDeleteParticipantmodal,
} from "../../store/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRemoveParticipantMutation } from "../../utils/api/event/eventApi";
import { TrashIcon } from "@heroicons/react/solid";
import { CogIcon } from "@heroicons/react/outline";
const DeleteParticipant: FC<{ selectedParticipant: ParticipantType, eventSlug:string }> = ({
  selectedParticipant,eventSlug
}) => {
 
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const open = useSelector(deleteParticipantModal);
  const [deleteParticipant,{
    isLoading:deleteParticipantLoading,
    error:deleteParticipantError
  }] = useRemoveParticipantMutation()
  const deleteParticipantEventHandler =  (participant:ParticipantType) => async() => {
    console.log(participant);
    const deleteParticipantResponse = await deleteParticipant({participant:selectedParticipant, eventSlug}).unwrap()
    dispatch(toogleDeleteParticipantmodal(false))
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(toogleDeleteParticipantmodal(false))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div> */}
                  <div className="pb-2 text-center sm:mt-0  sm:text-left w-full border-b  ">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Delete Participant?
                    </Dialog.Title>
                  </div>
                  <div className="mt-2 w-full ">
                    {`Are you sure you want to delete ${
                      selectedParticipant?.title || ""
                    } ${selectedParticipant?.first_name} ${
                      selectedParticipant?.last_name || ""
                    } from this event`}
                  </div>
                  <div className="mt-4 italic text-xs text-gray-700">
                    Deleting this participant only removes them from this
                    particular event.
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={deleteParticipantEventHandler(selectedParticipant)}
                  disabled={deleteParticipantLoading}
                  type="button"
                  className="w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-red-300 space-x-2"

                >
                  {deleteParticipantLoading?(<CogIcon className="animate-spin h-6 w-6"/>
                  ):(<TrashIcon className="h-6 w-6"/>)}
                  <span>Delete</span>
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                  onClick={() => dispatch(toogleDeleteParticipantmodal(false))}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteParticipant;
