import { FC, Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import { imageModal, toogleImageModal } from '../../store/modal/modalSlice';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/crop/crop';
import { setUserImage } from '../../store/user/userSlice';
import { useUploadImageMutation } from '../../utils/api/user/userApi';
import {useUploadUserImageMutation} from "../../utils/api/admin/adminApi";
import {useLocation, useSearchParams} from "react-router-dom";

interface CropEasyProp {
    photoUrl: any,setPhotoUrl?:any, setFile?:any, onOtherUserImageUpload?:any
}
const CropEasy: FC<CropEasyProp> = ({ photoUrl, setPhotoUrl, setFile, onOtherUserImageUpload}) => {
    // console.log(photoUrl)
    const dispatch = useDispatch();
    const open = useSelector(imageModal)

    let [searchParams] = useSearchParams();
    let location = useLocation();
    const [isAdmin, setIsAdmin] = useState(
        location.pathname === "/dashboard/admin/user/new"||location.pathname === "/dashboard/admin/user/view"
    );
    const cancelButtonRef = useRef(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels]= useState(null)
    const cropComplete = (croppedArea:any, croppedAreaPixels:any)=>{
        setCroppedAreaPixels(croppedAreaPixels)

    }
    const [uploadImage, {
        isLoading: uploadImageIsLoading,
        error: uploadImageError
    }] = useUploadImageMutation()

    const [uploadUserImage, {
        isLoading: uploadUserImageIsLoading,
        error: uploadUserImageError
    }] = useUploadUserImageMutation()
    const cropImage = async ()=>{
        try {
            const {file} = await getCroppedImg(photoUrl, croppedAreaPixels, rotation)
            console.log(file);
            const data = new FormData();
            data.append('file', file );


               if(isAdmin){
                    data.append('uid', searchParams.get('code')||'' );
                   console.log(data);
                    const uploadImageResponse= await uploadUserImage(data).unwrap()
                   console.log(uploadImageResponse)
                     if(onOtherUserImageUpload){
                          onOtherUserImageUpload(uploadImageResponse)
                     }
                   dispatch(toogleImageModal(false))
                }else{
                   const uploadImageResponse = await uploadImage(data).unwrap()
                   dispatch(setUserImage(uploadImageResponse))
                   dispatch(toogleImageModal(false))
               }
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => dispatch(toogleImageModal(false))}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="">
                                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div> */}
                                    <div className="mt-3 text-center sm:mt-0  sm:text-left w-full  ">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                                            Crop and Upload Your Image
                                        </Dialog.Title>

                                    </div>
                                    <div className="mt-2 w-full h-96 relative">
                                        <Cropper
                                            image={photoUrl}
                                            crop={crop}
                                            zoom={zoom}
                                            rotation={rotation}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onRotationChange={setRotation}
                                            onZoomChange={setZoom}
                                            onCropComplete={cropComplete}

                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-green-300"
                                    disabled={uploadImageIsLoading||uploadUserImageIsLoading}
                                    onClick={cropImage}
                                >
                                    {(uploadImageIsLoading||uploadUserImageIsLoading) && <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-white mr-4"></div>}
                                    Upload
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                    disabled={uploadImageIsLoading||uploadUserImageIsLoading}
                                    onClick={() => dispatch(toogleImageModal(false))}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default CropEasy