import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./views/App";
import Base from "./views/pages/Base";
import Home from "./views/pages/Home";
import DashboardBase from "./views/pages/Dashboard/Base";
import NotFound from "./views/pages/NotFound";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Opener from "./components/Loading/Opener";
// import ScrollToTop from "./hocs/ScrollToTop";
import Profile from "./views/pages/Profile/Profile";
import EventForm from "./views/pages/Dashboard/Events/EventForm";
import EventParticipants from "./views/pages/Dashboard/Events/EventParticipants";
import EventsAll from "./views/pages/Dashboard/Events/EventsAll";
import UsersAll from "./views/pages/Dashboard/Users/UsersAll";
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const Branches = lazy(() => import("./views/pages/Branch/Branches"));
const Events = lazy(() => import("./views/pages/Events/Events"));
const SelectedBranch = lazy(
  () => import("./views/pages/Branch/SelectedBranch")
);
const SelectedEvent = lazy(() => import("./views/pages/Events/SelectedEvent"));
const DashboardHome = lazy(() => import("./views/pages/Dashboard/Home/Home"));
const Auth = lazy(() => import("./views/pages/Auth"));
const Document = lazy(
  () => import("./views/pages/Dashboard/Downloadables/Document")
);
const DocumentList = lazy(
  () => import("./views/pages/Dashboard/Downloadables/DocumentList")
);
const PlayGround = lazy(
  () => import("./views/pages/Dashboard/Downloadables/PlayGround")
);
const CreateProfile = lazy(
  () => import("./views/pages/Dashboard/Profile/CreateProfile")
);
const BranchMembers = lazy(
  () => import("./views/pages/Dashboard/Branch/BranchMembers")
);
// const Participants = lazy(
//   () => import("./views/pages/Dashboard/Participant/Participants")
// );
let persistor = persistStore(store);
ReactDOM.render(
  <GoogleOAuthProvider clientId={googleClientID}>
    <Provider store={store}>
      <PersistGate loading={<Opener />} persistor={persistor}>
        <React.StrictMode>
          <BrowserRouter>
            {/* <ScrollToTop> */}
            <Routes>
              <Route path="/" element={<App />}>
                {/* home */}
                <Route path={""} element={<Base />}>
                  <Route index element={<Home />} />
                  {/* branches */}
                  <Route path={"branches"}>
                    <Route
                      index
                      element={
                        <Suspense fallback={<Opener />}>
                          <Branches />
                        </Suspense>
                      }
                    />

                    <Route
                      path={":slug"}
                      element={
                        <Suspense fallback={<Opener />}>
                          <SelectedBranch />
                        </Suspense>
                      }
                    />
                  </Route>
                  {/* events */}
                  <Route path={"events"}>
                    {/* events list */}
                    <Route
                      index
                      element={
                        <Suspense fallback={<Opener />}>
                          <Events />
                        </Suspense>
                      }
                    />

                    {/* selected event langing page */}
                    <Route
                      path={":slug"}
                      element={
                        <Suspense fallback={<Opener />}>
                          <SelectedEvent />
                        </Suspense>
                      }
                    />
                  </Route>
                  {/* auth */}
                  <Route
                    path="auth"
                    element={
                      <Suspense fallback={<Opener />}>
                        <Auth />
                      </Suspense>
                    }
                  />
                  {/* profile */}
                  <Route path="profile" element={<Profile />} />
                </Route>

                {/* <Route path=":chapter/"> */}
                {/* auth */}
                {/* <Route path='auth' element={
                <Suspense fallback={<Opener />}>
                  <Auth />
                </Suspense>
              }>
                
              </Route> */}

                {/* dashboard */}
                <Route path="dashboard" element={<ProtectedRoute />}>
                  <Route path="" element={<DashboardBase />}>
                    {/* home */}
                    <Route
                      index
                      element={
                        <Suspense fallback={<Opener />}>
                          <DashboardHome />
                        </Suspense>
                      }
                    />
                    {/* <Route path="poster/:pid" element={
                    <Suspense fallback={<Opener />}>
                      <Poster />
                    </Suspense>
                  } /> */}
                    <Route
                      path="document"
                      element={
                        <Suspense fallback={<Opener />}>
                          <Document />
                        </Suspense>
                      }
                    />
                    <Route
                      path="document/all"
                      element={
                        <Suspense fallback={<Opener />}>
                          <DocumentList />
                        </Suspense>
                      }
                    />
                    <Route
                      path="playground"
                      element={
                        <Suspense fallback={<Opener />}>
                          <PlayGround />
                        </Suspense>
                      }
                    />
                    {/* <Route
                    path="participants/:slug"
                    element={
                      <Suspense fallback={<Opener />}>
                        <Participants />
                      </Suspense>
                    }
                  /> */}
                    <Route
                      path="event/:slug"
                      element={
                        <Suspense fallback={<Opener />}>
                          <EventForm />
                        </Suspense>
                      }
                    />

                    <Route
                      path={"event/participants/:slug"}
                      element={
                        <Suspense fallback={<Opener />}>
                          <EventParticipants />
                        </Suspense>
                      }
                    />

                    {/* create/edit profile */}
                    <Route
                      path="profile"
                      element={
                        <Suspense fallback={<Opener />}>
                          <CreateProfile />
                        </Suspense>
                      }
                    />

                    {/* branch members */}
                    <Route
                      path="branch"
                      element={
                        <Suspense fallback={<Opener />}>
                          <BranchMembers />
                        </Suspense>
                      }
                    />
                    {/* admin routes */}
                    <Route
                      path="admin/events"
                      element={
                        <Suspense fallback={<Opener />}>
                          
                            <EventsAll />
                        
                        </Suspense>
                      }
                    />
                    <Route
                      path="admin/users"
                      element={
                        <Suspense fallback={<Opener />}>
                          
                            <UsersAll />
                        
                        </Suspense>
                      }
                    />
                    <Route
                      path="admin/user/new"
                      element={
                        <Suspense fallback={<Opener />}>
                          
                            <CreateProfile />
                        
                        </Suspense>
                      }
                    />
                    <Route
                      path="admin/user/view"
                      element={
                        <Suspense fallback={<Opener />}>
                          
                            <Profile />
                        
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
                {/* </Route> */}

                {/* not Found */}
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
            {/* </ScrollToTop> */}
          </BrowserRouter>
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,

  document.getElementById("root")
);
