import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section className=" h-screen w-full relative bg-gradient-to-r from-gray-50 via-gray-100 to-gray-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className=" max-w-7xl flex lg:flex-row flex-col-reverse mx-auto h-full px-6 sm:px-8 xl:px-0">
        {/* left */}
        <div className=" h-1/2 lg:h-auto flex flex-col items-start justify-start lg:justify-center lg:w-1/2 text-center lg:text-left px-6 lg:px-0    ">
          <h1 className="relative my-4 text-3xl font-black leading-tight text-gray-900 sm:text-6xl xl:mb-8 dark:text-gray-100">
            Verifiable lawyer profiles and events
          </h1>
          <p className="  mb-4 text-md text-gray-600 sm:text-lg xl:text-xl lg:pr-20 dark:text-gray-200">
            A new way for bar associations to manage and automate events and
            profiles.
          </p>
          <div className="w-full">
            <Button onClick={() => navigate("auth")} color={"primary"}>
              Get Started
            </Button>
          </div>
        </div>

        {/* right */}
        <div className="mt-8 lg:mt-0 flex justify-center lg:justify-end h-1/2 lg:h-auto items-center   lg:w-1/2 w-full relative  ">
          <motion.img
            // initial={{ x: 1 }}
            animate={{ y: 5, x: 10 }}
            transition={{ repeat: Infinity, repeatType: "mirror", duration: 1 }}
            src="/images/phone_mockup.png"
            className="h-full lg:h-auto mx-auto my-auto lg:mx-0 absolute z-0 lg:right-0 "
            alt="iphone mock"
          />
        </div>
      </div>
      <div className="mx-auto  absolute bottom-28 lg:bottom-20  flex w-full justify-center ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 animate-bounce dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
        </svg>
      </div>
    </section>
  );
};

export default Hero;
