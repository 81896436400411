import { baseApi } from "../baseApi";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWithCode: builder.query({
      query: (code) => `/user/get/${code}`,
    }),
    createProfile: builder.mutation({
      query: (profile) => ({
        url: `/user/createprofile`,
        method: "POST",
        body: profile,
      }),
    }),
    uploadImage: builder.mutation({
      query: (body) => ({
        url: `/user/upload`,
        method: "POST",
        body,
      }),
    }),
    verifyProfile: builder.mutation({
      query: (code) => ({
        url: `/user/verifyprofile`,
        method: "POST",
        body: code,
      }),
    }),
    editFullname: builder.mutation({
      query: (body) => ({
        url: `/user/edit/name`,
        method: "POST",
        body,
      }),
    }),
    editPhone: builder.mutation({
      query: (body) => ({
        url: `/user/edit/phone`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserWithCodeQuery,
  useCreateProfileMutation,
  useUploadImageMutation,
  useVerifyProfileMutation,
  useEditFullnameMutation,
  useEditPhoneMutation,
} = userApi;
