import { Menu, Transition } from "@headlessui/react";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import DeleteParticipant from "../../../../components/Modals/DeleteParticipant";
import EditParticipantDetails from "../../../../components/Modals/EditParticipantDetails";
import {
  toogleDeleteParticipantmodal,
  toogleEditParticipantModal,
} from "../../../../store/modal/modalSlice";
import {
  useGetAllParticipantsQuery,
  useLazyGetAllParticipantsQuery,
} from "../../../../utils/api/event/eventApi";
import RoleComponent from "../../../../components/RoleComponent";
import { useAuth } from "../../../../hooks/useAuth"; 
import EventTable from "../../../../components/Events/EventTable";

const EventsAll = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const user: UserType = useAuth();
  const [eventParams, setEventParams] = useState({
    slug: params.slug,
    page: 1,
  });
  const [participantsSheetData, setParticipantsSheetData] = useState<any>([]);
  // requests
  const {
    data: participantsData,
    isLoading: participantsIsLoading,
    isFetching: participantsIsFetching,
    isError: participantsIsError,
  } = useGetAllParticipantsQuery({
    slug: params.slug || "",
    search: location.search,
  });

  const [selectedParticipant, setSelectedParticipant] = useState({
    id: 0,
    first_name: "",
    email: "",
    uid: "",
  });
  const deleteParticipantHandler = (participant: ParticipantType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleDeleteParticipantmodal(true));
  };

  // const viewPosterClickHandler = (uuid: string) => () => {
  //   navigate(
  //     `/dashboard/document?event=${eventParams.slug}&type=tag&uid=${uuid}`
  //   );
  // };
  const editProfileClickHandler = (participant: ParticipantType) => () => {
    setSelectedParticipant(participant);
    dispatch(toogleEditParticipantModal(true));
  };

  const [getAllParticipants, { data, isLoading, isError }] =
    useLazyGetAllParticipantsQuery({});

  const nextPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage + 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const previousPageHandler = () => {
    const currentPage = parseInt(searchParams.get("page") || "1");
    searchParams.set("page", `${currentPage - 1}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <DeleteParticipant
        eventSlug={params.slug || ""}
        {...{ selectedParticipant }}
      />
      <EditParticipantDetails selectedParticipant={selectedParticipant} />
      <div className="relative  ">
        <EventTable />
      </div>
    </>
  );
};

export default EventsAll;
