import { baseApi } from "../baseApi";

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createProfileByAdmin: builder.mutation({
      query: (profile) => ({
        url: `/admin/user/create`,
        method: "POST",
        body: profile,
      }),
    }),
    getAllUsers: builder.query({
      query: (queryParams) => ({
        url: `/admin/users/all${queryParams}`,
        method: "GET",
      }),
      providesTags: (result, error, queryParams) => [
        { type: "AllUsers", queryParams },
      ],
    }),
    uploadUserImage: builder.mutation({
      query: (body) => ({
        url: `admin/user/upload`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateProfileByAdminMutation, useGetAllUsersQuery, useUploadUserImageMutation } = adminApi;
