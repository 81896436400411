import React, { ReactNode } from 'react'
type Props = {
    role: string;
    allowedRoles: string[];
    children: ReactNode;
  };
const RoleComponent = ({ role='user', allowedRoles, children }: Props) => {
    if (allowedRoles.includes(role)) {
        return <>{children}</>;
      } else {
        return null;
      }
}

export default RoleComponent