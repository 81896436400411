import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useAuth, useImage} from '../../hooks/useAuth'
import {toogleImageModal, toogleVerifyUserModal} from '../../store/modal/modalSlice'
import CropEasy from '../Modals/CropEasy'

const ProfileImage = ({
                          size = 'h-20 w-20',
                          otherUserImage = 's',
                          isAdmin = false
                      }) => {
    const user = useAuth()
    const userImage = useImage()
    const [otherUserImageFromServer, setOtherUserImageFromServer] = useState('')
    const dispatch = useDispatch();
    const [photoUrl, setPhotoUrl] = useState(userImage)
    const imageFileInput = useRef<HTMLInputElement>(null);


    const imageFileChange = (e: any) => {
        const file = e.target.files[0]
        if (file) {
            setPhotoUrl(URL.createObjectURL(file))
            dispatch(toogleImageModal(true))
        }

    }
    const handleImageClicked = () => {
        imageFileInput.current!.click()
    }
    console.log('other user image', otherUserImage, isAdmin)
    return (
        <>
            <CropEasy {...{photoUrl}} onOtherUserImageUpload={setOtherUserImageFromServer}/>
            <div className="flex-shrink-0 relative">
                <img
                    key={otherUserImageFromServer}
                    onClick={handleImageClicked}
                    className={`mx-auto rounded-full cursor-pointer ring-2 ring-white ${size}`}
                    src={
                        isAdmin ? otherUserImage ? `${process.env.REACT_APP_IMAGE_URL}images/${
                            otherUserImageFromServer?otherUserImageFromServer:otherUserImage
                            }` : "/images/placeholder.jpg"
                            :
                            userImage
                                ? userImage.includes("https")
                                    ? userImage
                                    : `${process.env.REACT_APP_IMAGE_URL}images/${userImage}`
                                : "/images/placeholder.jpg"
                    }
                    alt=""
                />
                <input
                    onChange={imageFileChange}
                    ref={imageFileInput}
                    id="imageUpload"
                    name="imageUpload"
                    type="file"
                    className="sr-only"
                />
                <label
                    htmlFor="imageUpload"
                    className="relative cursor-pointer "
                ></label>
            </div>
        </>
    );
};

export default ProfileImage;
