import { baseApi } from "./../baseApi";
 
const eventApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    eventList: builder.query<EventsPaginateType, string>({
      query: (queryParams) => `/events${queryParams}`,
      providesTags: ["Events"],
    }),
    getUserEvents: builder.query({
      query: (params) =>
        `user/events?user=${params.user_id}&page=${params.page}&limit=${params.limit}`,
    }),
    getEvent: builder.query({
      query: (params) => `events/${params.slug}?type=${params.type}`,
    }),
    getParticipants: builder.query({
      query: (params) =>
        `/event/participants/${params.slug}?page=${params.page}&all=${params.all}`,
      // transformResponse:(res:any) =>res.sort((a,b)=>b.id-a.id),
      providesTags: ["Participants"],
    }),
    getAllParticipants: builder.query<any, any>({
      query: (queryParams) => ({
        url: `/event/participants/${queryParams.slug}/all${queryParams.search?`${queryParams.search}`:''}`,
        method: "GET",
      }),
      // transformResponse:(res:any) =>res.sort((a,b)=>b.id-a.id),
      providesTags: (result, error, queryParams) => [
        { type: "AllParticipants", queryParams },
      ],
    }),
    editParticipant: builder.mutation({
      query: (participant) => ({
        url: `/event/participant/edit`,
        method: "POST",
        body: participant,
      }),
      invalidatesTags: ["Participants"],
    }),
    removeParticipant: builder.mutation({
      query: (body) => ({
        url: `/event/participant/remove`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Participants"],
    }),
    registerEvent: builder.query({
      query: (params) => `/event/register/${params.slug}`,
    }),
    registerUserForEvent: builder.mutation({
      query: (body) => ({
        url: `/event/user/register`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useRegisterEventQuery,
  useEventListQuery,
  useGetUserEventsQuery,
  useGetEventQuery,
  useGetParticipantsQuery,
  useLazyGetAllParticipantsQuery,
  useGetAllParticipantsQuery,
  useEditParticipantMutation,
  useRemoveParticipantMutation,
  useRegisterUserForEventMutation,
} = eventApi;
