import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
const initialState: { notification: NotificationType; timeOut: number , show:boolean} = {
  notification: {
    type: "normal",
    title: "",
  },
  timeOut: 0, 
  show:false,
};
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => { 
      state.notification = action.payload;
      state.timeOut=2000;
      state.show=true
    },
 
    toogleNotification:(state,action)=>{
      state.show=action.payload
    },
   
  },
});
export const {setNotification,toogleNotification}= notificationSlice.actions
export default notificationSlice.reducer;
export const NotificationState = (state: RootState) =>state.notification.show
export const notification = (state: RootState)=>state.notification.notification
