import { FC } from "react";
// import Navigation from "../components/Navigation";
import { Outlet } from "react-router-dom";
 

const App: FC= ()=> {
  return (
    <main className="relative h-screen  ">
      
    <div className="fixed top-0 z-50 w-full">
      {/* <Navigation></Navigation> */}
    </div>
    <Outlet/> 

  </main>
  );
}

export default App;
