import React, { FC, MouseEventHandler, ReactNode } from "react";
export interface NewButtonType {
  children?: ReactNode;
  as?: "button" | "div"; 
  size?: "xs" | "sm" | "md" | "base" | "lg";
  className?: string;
  circular?: true | false;
  block?: true | false;
  background?: true | false;
  disabled?: true | false;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const NewButton: FC<NewButtonType> = ({
  children, 
  size = "base",
  className,
  background = true,
  circular = false,
  block = false,
  onClick,
  as = "button",
  iconLeft = <></>,
  iconRight = <></>,
  ...props
}) => {
  const classNames = `
    ${className}
    
 cursor-pointer inline-flex justify-center items-center text-white  hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-700 focus:ring-yellow-400 relative  border border-transparent disabled:bg-gray-200 disabled:cursor-auto rounded

 text-${size}
 py-1.5 px-2.5  font-bold  leading-6
   
  `;
  return (
    <>
      {as === "button" ? (
        <button {...props} onClick={onClick} className={classNames}>
          {iconLeft}
          {children}
          {iconRight}
        </button>
      ) : (
        <span {...props} onClick={onClick} className={classNames}>
          {iconLeft}
          {children}
          {iconRight}
        </span>
      )}
    </>
  );
};

export default NewButton;
